export const config = {
  // time related formats are following: https://day.js.org/docs/en/display/format
  dateFormat: 'YYYY-MM-DD', // zero-padded displayed
  timeFormat: 'HH:mm', // 24-hour display with zero-padded
}

export const percentileDataTypes = [
  { value: 'prem', label: 'Prem' },
  {
    value: 'apr',
    label: 'APR',
  },
]

export const percentileDataDays = [
  { value: 1, label: '1d' },
  { value: 3, label: '3d' },
  { value: 7, label: '7d' },
  { value: 30, label: '30d' },
]
