import { ETFTMinusData } from '../../../types/etf'
import CoinTable, { FieldConfig } from './CoinTable'

type TickerMapTableProps = {
  data: Record<string, ETFTMinusData>
}

const fieldNameMap: Partial<Record<keyof ETFTMinusData, FieldConfig>> = {
  cmeFutureFirstMonthTickerName: {
    label: 'CME 1st',
    type: 'text',
    editable: true,
  },
  cmeFutureSecondMonthTickerName: {
    label: 'CME 2nd',
    type: 'text',
    editable: true,
  },
}

const TickerMapTable: React.FC<TickerMapTableProps> = ({ data }) => {
  return (
    <CoinTable subtitle="Ticker Map" data={data} fieldNameMap={fieldNameMap} />
  )
}

export default TickerMapTable
