import { isNaN } from 'lodash-es'

import { Comp as Base } from '../../../components/DataTable/base'
import { coinEtfMap } from '../constants'
import UpdatableNumberCell, {
  UpdatableNumberCellProps,
} from './UpdatableNumberCell'

export type FieldConfig = {
  label: string
  editable: boolean
  type: UpdatableNumberCellProps['type']
  format?: (val: any) => string
}

type CoinTableProps<T extends Record<string, any>> = {
  subtitle?: string
  data: Record<string, T>
  fieldNameMap: Partial<Record<keyof T, FieldConfig>>
}

const CoinTable: React.FC<CoinTableProps<Record<string, any>>> = ({
  subtitle = '',
  data,
  fieldNameMap,
}) => {
  return (
    <Base.Table>
      <Base.Thead>
        <Base.Tr>
          <Base.Th rowSpan={1} style={{ width: '240px' }}>
            {subtitle ?? ''}
          </Base.Th>
          {Object.keys(data).map(coin => {
            return (
              <Base.Th key={coin} style={{ width: '200px' }}>
                {coinEtfMap[coin] || coin}
              </Base.Th>
            )
          })}
        </Base.Tr>
      </Base.Thead>
      <Base.Tbody>
        {Object.keys(fieldNameMap).map(fieldKey => {
          const col = fieldNameMap[fieldKey] as FieldConfig
          return (
            <Base.Tr key={fieldKey} style={{ height: '40px' }}>
              <Base.Td>{col.label}</Base.Td>
              {Object.keys(data).map(coin => {
                const value = (data[coin] as Record<string, any>)[fieldKey]

                let displayValue = ''
                if (col.format) {
                  displayValue = col.format(value)
                } else if (isNaN(value)) {
                  displayValue = 'N/A'
                } else {
                  displayValue = String(value)
                }

                if (col.editable) {
                  return (
                    <UpdatableNumberCell
                      key={coin}
                      coin={coin}
                      fieldKey={fieldKey}
                      displayValue={displayValue}
                      type={col.type}
                    />
                  )
                }

                return <Base.Td key={coin}>{displayValue}</Base.Td>
              })}
            </Base.Tr>
          )
        })}
      </Base.Tbody>
    </Base.Table>
  )
}

export default CoinTable
