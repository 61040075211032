import {
  Arrow,
  Content,
  Provider,
  Root,
  Trigger,
} from '@radix-ui/react-tooltip'

import { styled } from '../styles'
import Text from './Text'

type TooltipProps = {
  message: string
  children: JSX.Element
}

const StyledArrow = styled(Arrow, {})

const StyledContent = styled(Content, {
  borderRadius: '$medium',
  padding: '$small $small',
  fontSize: '$small',
  color: '$text',
  backgroundColor: '$primary',
  [`& ${StyledArrow}`]: {
    fill: '$primary',
  },
})

const Tooltip: React.FC<TooltipProps> = ({ message, children }) => {
  return (
    <Provider delayDuration={300}>
      <Root>
        <Trigger asChild>{children}</Trigger>
        <StyledContent>
          <Text>{message}</Text>
          <StyledArrow />
        </StyledContent>
      </Root>
    </Provider>
  )
}

export default Tooltip
