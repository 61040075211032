import { FormEventHandler, useRef, useState } from 'react'

import customFetch from '../../api/customFetch'
import { useAuth } from '../../hooks'
import { styled } from '../../styles'
import { SimpleApiResponse } from '../../types'
import Button from '../Button'
import { TextInput } from '../Inputs'
import Modal from '../Modal'
import Text from '../Text'

type ChangePasswordModalProps = {
  trigger: React.ReactNode
}

const StyledForm = styled('form', {
  position: 'relative',
  marginTop: '$medium',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '$medium',
})

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  trigger,
}) => {
  const { accountInfo } = useAuth()
  const closeBtnRef = useRef<HTMLButtonElement>(null)
  const [oldPassword, setOldPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [errorMsg, setErrorMsg] = useState<string>('')

  const handleSubmit: FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault()

    setErrorMsg('')

    const resData = await customFetch<SimpleApiResponse>(
      '/Account/ChangePassword',
      {
        data: {
          newPassword,
          password: oldPassword,
          userName: accountInfo?.username,
        },
        method: 'POST',
      }
    )

    if (!resData.isSuccess) {
      setErrorMsg(resData.message || 'Unknown error.')
    } else {
      // eslint-disable-next-line no-alert
      alert('Password changed successfully.')
      closeBtnRef.current?.click()
    }
  }

  return (
    <Modal trigger={trigger}>
      <Modal.Title>Change Password</Modal.Title>
      <Text css={{ fontSize: '$tiny' }}>
        Tips: After the change is successful, the next login takes effect
      </Text>
      <StyledForm onSubmit={handleSubmit}>
        <TextInput
          required
          type="password"
          name="oldPassword"
          label="Old Password"
          value={oldPassword}
          onChange={e => setOldPassword(e.target.value)}
        />
        <TextInput
          required
          type="password"
          name="newPassword"
          label="New Password"
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
        />
        {errorMsg && <Text css={{ color: '$warn' }}>{errorMsg}</Text>}
        <Modal.CloseButton css={{ display: 'none' }} ref={closeBtnRef} />
        <Button type="submit">Submit</Button>
      </StyledForm>
    </Modal>
  )
}

export default ChangePasswordModal
