import {
  Content,
  Item,
  List,
  Root,
  Trigger,
} from '@radix-ui/react-navigation-menu'
import { ComponentProps, CSS } from '@stitches/react'
import { forwardRef } from 'react'
import { MdArrowDropDown } from 'react-icons/md'
import { Link as RouterLink } from 'react-router-dom'

import { ROUTE_ITEMS } from '../../constants'
import { styled, ThemeConfig } from '../../styles'
import {
  GroupRouteItem,
  isGroupRouteItem,
  isSingleRouteItem,
  SingleRouteItem,
} from '../../types'

const itemStyles: CSS<ThemeConfig> = {
  outline: 'none',
  userSelect: 'none',
  fontWeight: 'bold',
  borderRadius: '$small',
  fontSize: '$medium',
  color: '$text',
  '&:hover': {
    opacity: '0.7',
  },
}

const StyledCaret = styled(MdArrowDropDown, {
  position: 'relative',
  '[data-state=open] &': { transform: 'rotate(-180deg)' },
  '@media (prefers-reduced-motion: no-preference)': {
    transition: 'transform 150ms ease',
  },
})

const StyledTrigger = styled(Trigger, {
  all: 'unset',
  ...itemStyles,
  padding: '$tiny $small',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 2,
})

const NavMenuItemTrigger = forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof StyledTrigger>
>(({ children, ...props }, ref) => {
  return (
    <StyledTrigger {...props} ref={ref}>
      {children}
      <StyledCaret aria-hidden />
    </StyledTrigger>
  )
})

const NavMenuItemContent = styled(Content, {
  position: 'absolute',
  transformOrigin: 'top center',
  borderRadius: '$medium',
  minWidth: '110px',
  backgroundColor: '$background',
  boxShadow: '0 0 10px $colors$shadowColor',
})

const NavMenuItemLink = styled(RouterLink, {
  ...itemStyles,
  padding: '$tiny $small',
  display: 'block',
  textDecoration: 'none',
  fontSize: '$medium',
})

const NavMenuRoot = styled(Root, {
  position: 'relative',
  flexRowCenter: 'center',
  width: '100%',
  zIndex: 1,
})

const NavMenuList = styled(List, {
  all: 'unset',
  flexRowCenter: 'center',
  gap: '$medium',
  padding: '$min',
  borderRadius: '$medium',
  listStyle: 'none',
})

const SingleNavItem: React.FC<SingleRouteItem> = ({ label, url }) => {
  return (
    <Item>
      <NavMenuItemLink to={url}>{label}</NavMenuItemLink>
    </Item>
  )
}

const GroupNavItem: React.FC<GroupRouteItem> = ({ label, items }) => {
  return (
    <Item>
      <NavMenuItemTrigger>{label}</NavMenuItemTrigger>
      <NavMenuItemContent>
        {items.map(item => {
          return (
            <NavMenuItemLink key={item.url} to={item.url}>
              {item.label}
            </NavMenuItemLink>
          )
        })}
      </NavMenuItemContent>
    </Item>
  )
}
const NavMenu: React.FC = () => {
  return (
    <NavMenuRoot>
      <NavMenuList>
        {ROUTE_ITEMS.map((el, idx) => {
          if (isSingleRouteItem(el)) {
            // eslint-disable-next-line react/no-array-index-key
            return <SingleNavItem key={`${el.label}_${idx}`} {...el} />
          }
          if (isGroupRouteItem(el)) {
            // eslint-disable-next-line react/no-array-index-key
            return <GroupNavItem key={`${el.label}_${idx}`} {...el} />
          }
          return null
        })}
      </NavMenuList>
    </NavMenuRoot>
  )
}

export default NavMenu
