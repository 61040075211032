// Value of each pairs is mapped to backend, which should not be changed
export const OVERVIEW = {
  Futures: 'Futures',
  Swaps: 'Swaps',
  SimpleEarn: 'SimpleEarn',
  Spots: 'Spots',
  DerivBTC: 'DerivBTC',
  DerivETH: 'DerivETH',
}

export const EXCHANGE = {
  Binance: 'Binance',
  Bitfinex: 'Bitfinex',
  BitMex: 'BitMex',
  Bybit: 'Bybit',
  CoinFlex: 'CoinFlex',
  DYDX: 'DYDX',
  KuCoin: 'KuCoin',
  Ftx: 'Ftx',
  AscendEX: 'AscendEX',
  CryptoCom: 'CryptoCom',
  Deribit: 'Deribit',
  DeFi: 'DeFi',
  GateIO: 'GateIO',
  Huobi: 'Huobi',
  Kraken: 'Kraken',
  OKEx: 'OKEx',
  Stable: 'Stable',
}

export const COIN = {
  BTC: 'Btc',
  LTC: 'Ltc',
  ETH: 'Eth',
  BCH: 'Bch',
  XRP: 'Xrp',
  EOS: 'Eos',
}

export const ETF = {
  ETF: 'ETF',
}

const MISC = {
  Admin: 'Admin',
}

export const DATA_CATEGORY_MAP = {
  ...OVERVIEW,
  ...EXCHANGE,
  ...COIN,
  ...ETF,
  ...MISC,
} as const

export const DATA_CATEGORIES = Object.values(DATA_CATEGORY_MAP)

// export const DATA_CATEGORY_OPTIONS = Object.entries(allCategories).map(
//   ([label, value]) => ({ label, value })
// )
