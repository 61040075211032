/* eslint-disable camelcase */
import { sum } from 'ramda'
import { useState } from 'react'
import { v4 as uuid } from 'uuid'

import customFetch from '../../../api/customFetch'
import { styled } from '../../../styles'
import { ApiResponse } from '../../../types'
import { FutureSwap } from '../../../types/business'
import { isNullOrUndefined } from '../../../utils'
import Button from '../../Button'
import Modal from '../../Modal'
import Spinner from '../../Spinner'
import DataTable, { DataTableColumns } from '../base'

type GetRateInfoParam = {
  id: FutureSwap['id']
  common_id: FutureSwap['common_id']
  exchange_raw: FutureSwap['exchange_raw']
}

type RateInfo = {
  datetime: string
  fundingRate: number
}

type RateInfoCellProps = {
  currentValue: string
  dailySettlementCount?: number
  multiplier?: number
} & GetRateInfoParam

const getRateInfo = async ({
  common_id,
  exchange_raw,
  id,
}: GetRateInfoParam): Promise<
  ApiResponse<
    Array<
      GetRateInfoParam & {
        rates: RateInfo[]
      }
    >
  >
> => {
  return customFetch('/RateInfo/RateHistory', {
    data: {
      requestId: uuid(),
      data: [
        {
          common_id,
          exchange_raw,
          id,
        },
      ],
    },
    method: 'POST',
  })
}

const avgRateColumns: DataTableColumns<
  Record<1 | 3 | 10 | 30, number> | undefined
> = [
  { label: '1 Day', render: row => row?.[1] || 'N/A', width: '125px' },
  { label: '3 Days', render: row => row?.[3] || 'N/A', width: '125px' },
  { label: '10 Days', render: row => row?.[10] || 'N/A', width: '125px' },
  { label: '30 Days', render: row => row?.[30] || 'N/A', width: '125px' },
]

const columns: DataTableColumns<RateInfo> = [
  {
    label: 'Datetime (HKT)',
    render: row => row.datetime,
    width: '250px',
  },
  {
    label: 'Funding Rate',
    render: row => `${(row.fundingRate * 100).toFixed(3)}%`,
    width: '250px',
  },
]

const ContentWrapper = styled('div', {
  overflow: 'hidden',
})

export const RateInfoCell: React.FC<RateInfoCellProps> = ({
  currentValue,
  dailySettlementCount = 3,
  id,
  common_id,
  exchange_raw,
}) => {
  const [rateAvgs, setRateAvgs] = useState<Record<1 | 3 | 10 | 30, number>>()
  const [records, setRecords] = useState<RateInfo[]>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  // fetch data on modal open
  // calculate 1, 3, 10, 30 days average
  const onOpenChange = async (open: boolean) => {
    if (open) {
      setIsLoading(true)
      try {
        const resData = await getRateInfo({ id, common_id, exchange_raw })
        if (resData.isSuccess) {
          const resRecords = resData.data?.[0].rates || []
          const ratesArr = resRecords.map(el => el.fundingRate * 100)

          const rateAvgsMap = [1, 3, 10, 30].reduce((acc, days) => {
            const value =
              resRecords.length / dailySettlementCount >= days
                ? `${sum(
                    ratesArr.slice(0, dailySettlementCount * days)
                  ).toFixed(3)}%`
                : null

            return {
              ...acc,
              [days]: value,
            }
          }, {})

          setRateAvgs(rateAvgsMap as any)
          setRecords(resRecords)
        }
      } finally {
        setIsLoading(false)
      }
    }
  }

  if (isNullOrUndefined(currentValue)) {
    return null
  }

  return (
    <Modal
      onOpenChange={onOpenChange}
      trigger={
        <Button
          css={{
            height: 'unset',
            fontSize: '$small',
            padding: '0 $tiny',
            borderRadius: '$small',
            fontWeight: 'normal',
          }}
        >
          {currentValue}
        </Button>
      }
    >
      <Modal.Title>Rate Info ({common_id})</Modal.Title>
      <ContentWrapper>
        {!records || isLoading ? (
          <Spinner />
        ) : (
          <>
            <DataTable
              rowKey=""
              title=""
              columns={avgRateColumns}
              data={[rateAvgs]}
            />
            <DataTable
              rowKey="datetime"
              title=""
              columns={columns}
              data={records}
              tableHeight="800px"
            />
          </>
        )}
      </ContentWrapper>
    </Modal>
  )
}
