import { atom } from 'recoil'

import { ATOM_KEY } from '../constants'
import { localStorageEffect } from './utils'

export const percentileTypes = atom<string>({
  key: ATOM_KEY.PercentileDataTypes,
  default: 'prem',
  effects: [localStorageEffect(ATOM_KEY.PercentileDataTypes)],
})

export const percentileDays = atom<number>({
  key: ATOM_KEY.PercentileDataDays,
  default: 3,
  effects: [localStorageEffect(ATOM_KEY.PercentileDataDays)],
})
