import dayjs from 'dayjs'

import Button from '../../../components/Button'
import Text from '../../../components/Text'
import { styled } from '../../../styles'
import { TransformedETFPageStreamData } from '../transform'
import ETFCMEReplicationDataTable from './ETFCMEReplicationDataTable'
import ETFTMinusDataTable from './ETFTMinusDataTable'
import TickerMapTable from './TickerMapTable'
import { useTMinusDataEditState } from './tMinusDataEditState'

type TMinusParameterTabProps = {
  data: TransformedETFPageStreamData
}

const TableGroup = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'start',
  justifyContent: 'start',
  padding: '$medium 0',
  gap: '$medium',
})

const ButtonWrapper = styled('div', {
  flexRowCenter: 'start',
  flexDirection: 'column',
  gap: '$small',
  '& > button': {
    width: '150px',
  },
})

const TMinusParameterTab: React.FC<TMinusParameterTabProps> = ({ data }) => {
  const { isEditing, startEdit, cancelEdit, save } = useTMinusDataEditState()
  const { lastUpdateTime } = Object.values(data.parameters)[0]
  const lastUpdateTimeStr = lastUpdateTime
    ? `${dayjs(lastUpdateTime).format('YYYY-MM-DD HH:mm:ss')} (${dayjs(
        lastUpdateTime
      ).fromNow()})`
    : 'N/A'
  return (
    <>
      <Text>{`Last Update: ${lastUpdateTimeStr}`}</Text>

      <TableGroup>
        <ETFCMEReplicationDataTable data={data.parameters} />
        <ETFTMinusDataTable data={data.parameters} />
        <ButtonWrapper>
          <Button
            disabled={isEditing}
            onClick={() => !isEditing && startEdit()}
          >
            Edit
          </Button>
          <Button
            disabled={!isEditing}
            onClick={cancelEdit}
            css={{
              background: '$warn',
            }}
          >
            Cancel Edit
          </Button>
          <Button disabled={!isEditing} onClick={save}>
            Save
          </Button>
        </ButtonWrapper>
      </TableGroup>
      <TickerMapTable data={data.parameters} />
    </>
  )
}

export default TMinusParameterTab
