import { toNumber } from 'lodash-es'

import { TokenSupplyInfo } from '../../types/business'
import { thousandSeparate } from '../../utils'
import DataTable, { DataTableColumns } from './base'

type TokenSupplyInfoTableProps = {
  data: TokenSupplyInfo[]
}

const columns: DataTableColumns<TokenSupplyInfo> = [
  {
    label: 'Stable Coin',
    render: row => row.currency,
  },
  {
    label: 'Total Supply',
    render: row => thousandSeparate(toNumber(row.totalSupply)),
    width: '500px',
  },
]

export const TokenSupplyInfoTable: React.FC<TokenSupplyInfoTableProps> = ({
  data,
}) => {
  return <DataTable rowKey="currency" title="" columns={columns} data={data} />
}
