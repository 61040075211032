export const coinEtfTickerMap: Record<string, string> = {
  BTC: '3066',
  ETH: '3068',
}

export const coinEtfMap: Record<string, string> = {
  BTC: 'CSOP BTC FUTURES ETF',
  ETH: 'CSOP ETH FUTURES ETF',
}

export const coinEtfLotSizeMap: Record<string, number> = {
  BTC: 5,
  ETH: 50,
}
