import { useEffect } from 'react'
import { useRecoilValue } from 'recoil'

import { darkModeState } from '../stores'
import { darkTheme } from '../styles'

const themeRoot = document.body

export const useDarkMode = () => {
  const isDarkMode = useRecoilValue(darkModeState)

  useEffect(() => {
    if (isDarkMode) {
      themeRoot.classList.add(darkTheme)
    } else {
      themeRoot.classList.remove(darkTheme)
    }
  }, [isDarkMode])
}
