import { Navigate, Outlet } from 'react-router-dom'

import { PATH } from '../constants/path'
import { useAuth } from '../hooks'

const ProtectedRoute: React.FC = () => {
  const { isLogin } = useAuth()

  if (!isLogin) {
    return <Navigate to={PATH.Login} replace />
  }
  return <Outlet />
}

export default ProtectedRoute
