import {
  ExchangeDetailsConfig,
  FundingRateAggregation,
  FutureDeliveryInfo,
  FutureSwap,
} from '../../types/business'
import { EXCHANGE } from '../dataCategory'

type KrakenStreamData = {
  fundingAggInfo: FundingRateAggregation[]
  futureInfo: FutureDeliveryInfo[]
  takerTakerData: FutureSwap[]
  tabName: string
}

const tabs = ['All Derivs', 'Funding Rate', 'Misc']

export const KrakenConfig: ExchangeDetailsConfig<KrakenStreamData> = {
  title: 'Kraken',
  dataCategory: EXCHANGE.Kraken,
  tabs: {
    defaultValue: tabs[0],
    labels: tabs,
  },
  tables: [
    {
      type: 'FutureSwap',
      dataKey: 'takerTakerData',
      tableProps: {
        title: 'Kraken vs All Derivs (Taker / Taker Premium)',
        dailySettlementCount: 6,
      },
      tab: tabs[0],
    },
    {
      type: 'FundingRate',
      dataKey: 'fundingAggInfo',
      tableProps: null,
      tab: tabs[1],
    },
    {
      type: 'FutureDelivery',
      dataKey: 'futureInfo',
      tableProps: null,
      tab: tabs[2],
    },
  ],
}
