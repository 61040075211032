import { ExchangeDetailsConfig } from '../../types/business'
import { COIN, EXCHANGE, OVERVIEW } from '../dataCategory'
import { AscendEXConfig } from './AscendEX'
import { BCHConfig } from './BCH'
import { BinanceConfig } from './Binance'
import { BitfinexConfig } from './Bitfinex'
import { BitMexConfig } from './BitMex'
import { BTCConfig } from './BTC'
import { BybitConfig } from './Bybit'
import { CoinFlexConfig } from './CoinFlex'
import { CryptoComConfig } from './CryptoCom'
import { DeFiConfig } from './DeFi'
import { DeribitConfig } from './Deribit'
import { DerivBTCConfig } from './DerivBTC'
import { DerivETHConfig } from './DerivETH'
import { DYDXConfig } from './DYDX'
import { ETHConfig } from './ETH'
import { FtxConfig } from './FTX'
import { FuturesConfig } from './Futures'
import { GateIOConfig } from './GateIO'
import { HuobiConfig } from './Huobi'
import { KrakenConfig } from './Kraken'
import { KuCoinConfig } from './KuCoin'
import { LTCConfig } from './LTC'
import { OKExConfig } from './OKEx'
import { SimpleEarnConfig } from './SimpleEarn'
import { SpotsConfig } from './Spots'
import { StableConfig } from './Stable'
import { SwapsConfig } from './Swaps'
import { XRPConfig } from './XRP'

const PAGE_SCHEMA_CONFIG = {
  [OVERVIEW.Futures]: FuturesConfig,
  [OVERVIEW.Swaps]: SwapsConfig,
  [OVERVIEW.SimpleEarn]: SimpleEarnConfig,
  [OVERVIEW.Spots]: SpotsConfig,
  [OVERVIEW.DerivBTC]: DerivBTCConfig,
  [OVERVIEW.DerivETH]: DerivETHConfig,
  [EXCHANGE.BitMex]: BitMexConfig,
  [EXCHANGE.Bitfinex]: BitfinexConfig,
  [EXCHANGE.Ftx]: FtxConfig,
  [EXCHANGE.Binance]: BinanceConfig,
  [EXCHANGE.Bybit]: BybitConfig,
  [EXCHANGE.CoinFlex]: CoinFlexConfig,
  [EXCHANGE.DYDX]: DYDXConfig,
  [EXCHANGE.KuCoin]: KuCoinConfig,
  [EXCHANGE.AscendEX]: AscendEXConfig,
  [EXCHANGE.CryptoCom]: CryptoComConfig,
  [EXCHANGE.Deribit]: DeribitConfig,
  [EXCHANGE.DeFi]: DeFiConfig,
  [EXCHANGE.GateIO]: GateIOConfig,
  [EXCHANGE.Huobi]: HuobiConfig,
  [EXCHANGE.Kraken]: KrakenConfig,
  [EXCHANGE.OKEx]: OKExConfig,
  [EXCHANGE.Stable]: StableConfig,
  [COIN.BTC]: BTCConfig,
  [COIN.ETH]: ETHConfig,
  [COIN.LTC]: LTCConfig,
  [COIN.BCH]: BCHConfig,
  [COIN.XRP]: XRPConfig,
} as Record<string, ExchangeDetailsConfig>

export default PAGE_SCHEMA_CONFIG
