/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from '@microsoft/signalr'
import { toast } from 'react-toastify'

import { DATA_CATEGORIES, OVERVIEW, PATH, TOAST_KEY } from '../constants'

const baseUrl = import.meta.env.VITE_API_BASE_URL || ''

const wrapLog = (message: string) => [
  `%c[${new Date().toISOString()}] ${message}`,
  'background: teal; color: white',
]

export default class CoinStreamDataStream {
  private static connection: HubConnection | null = null

  private static dataCategory: string

  private static hangingTimer: NodeJS.Timer | null = null

  private static lastMsgTs = -1

  static noReconnect = false

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private static messageHandler: (args: any) => void = () => {}

  static getConnection() {
    return this.connection
  }

  static isHanging(): boolean {
    const now = Date.now()
    return this.lastMsgTs !== -1 && now - this.lastMsgTs > 5000
  }

  static isHealthy(): boolean {
    return !(
      !this.connection ||
      this.connection.state === HubConnectionState.Disconnected ||
      this.isHanging()
    )
  }

  // reset state
  static reset(): void {
    if (this.hangingTimer) {
      clearInterval(this.hangingTimer)
      this.hangingTimer = null
    }
    this.lastMsgTs = -1
    this.noReconnect = false
    if (this.connection !== null) {
      this.connection.off('KickOut')
      DATA_CATEGORIES.forEach(category => {
        this.connection?.off(category)
      })
    }
    // set connection to null lastly, after unregister the messageHandler
    this.connection = null
  }

  static getNewConnection(dataCategory = OVERVIEW.DerivBTC) {
    this.dataCategory = dataCategory
    const newConnection = new HubConnectionBuilder()
      .withUrl(`${baseUrl}/kernelHub?page=${dataCategory}`, {
        accessTokenFactory: () => {
          const accountInfo = JSON.parse(sessionStorage.accountInfo || '')
          return accountInfo.token
        },
      })
      .build()

    // When connection is terminated by server
    newConnection.on('KickOut', () => {
      this.noReconnect = true
      this.connection?.stop()
    })

    newConnection.onclose(async err => {
      console.log(...wrapLog(`Data stream closed - ${this.dataCategory}`))
      if (err) {
        console.error(err)
      }
      if (this.noReconnect) {
        console.log(
          ...wrapLog(`Connection terminated by server - ${this.dataCategory}`)
        )
        // toast.error(
        //   'Your connection have been terminated by server, please reconnect manually',
        //   {
        //     toastId: TOAST_KEY.DataStreamConnectionTerminated,
        //     autoClose: false,
        //   }
        // )
        this.reset()
      } else {
        this.reconnectDataStream()
      }
    })

    DATA_CATEGORIES.forEach(category => {
      newConnection.on(category, data => {
        this.lastMsgTs = Math.max(this.lastMsgTs, Date.now())
        this.messageHandler(data)
      })
    })

    this.hangingTimer = setInterval(() => {
      if (!this.noReconnect && !this.isHealthy()) {
        this.reconnectDataStream()
      }
    }, 1000)

    return newConnection
  }

  static openDataStream(dataCategory: string, forceNew = false) {
    this.noReconnect = false
    if (this.connection === null || forceNew) {
      console.log(...wrapLog(`Open data stream - ${dataCategory}`))
      this.reset()
      this.connection = this.getNewConnection(dataCategory)
      // connection state is disconnected right after create
    }

    if (
      this.connection.state === HubConnectionState.Connected &&
      this.dataCategory !== dataCategory
    ) {
      this.changeDataCategory(dataCategory)
    }

    if (this.connection.state === HubConnectionState.Disconnected) {
      this.connection
        .start()
        .then(() => {
          console.log(...wrapLog(`Data stream started - ${this.dataCategory}`))
          toast.dismiss(TOAST_KEY.DataStreamConnectionTerminated)
        })
        .catch(err => {
          console.error(err)
          console.log(
            ...wrapLog(`Failed to start data stream - ${this.dataCategory}`)
          )
          this.reconnectDataStream()
        })
    }
  }

  // user will only reconnect on current page
  // new openDataStream will be called when user switch pages
  static reconnectDataStream() {
    // reconnect
    console.log(...wrapLog(`Reconnect data stream - ${this.dataCategory}`))
    this.openDataStream(this.dataCategory, true)
  }

  static changeDataCategory(dataCategory: string) {
    console.log(...wrapLog(`Change data category - ${dataCategory}`))
    this.dataCategory = dataCategory

    if (this.connection === null) {
      throw new Error('CoinStreamDataStream has not been initialized')
    }

    this.connection
      .invoke('ChangeTab', dataCategory)
      .then(() => {
        console.log(`Successfully changed to data category ${dataCategory}`)
      })
      .catch(err => {
        console.error(err)
        console.log(
          ...wrapLog(`Failed to change data category - ${this.dataCategory}`)
        )
        if (err.statusCode === 401) {
          // invalid token
          sessionStorage.clear()
          window.location.href = PATH.Login
          // eslint-disable-next-line no-alert
          alert('Session invalid. Please login again.')
        } else {
          this.reconnectDataStream()
        }
      })
  }

  // Register event listener for different data category
  static setMessageHandler<T = Record<string, unknown>>(
    func: (args?: T) => void
  ) {
    this.messageHandler = func
  }
}
