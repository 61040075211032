import { useRecoilState } from 'recoil'

import { percentileTypes } from '../../../stores/percentileData'
import { styled } from '../../../styles'
import { PercentileData, PercentilePercentData } from '../../../types/business'
import { isNullOrUndefined, toPercentStr } from '../../../utils'
import Text from '../../Text'

type PercentileDataCellProps = {
  data: PercentileData | null
}

const CellFormatWrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  lineHeight: '24px',
  '& > *': {
    borderLeft: '1px solid $borderColor',
    borderRight: '1px solid $borderColor',
    minWidth: '74px',
  },
  '& > *:first-child': {
    borderLeft: 'none',
  },
  '& > *:last-child(1)': {
    borderRight: 'none',
  },
})

export const PercentileDataCell: React.FC<PercentileDataCellProps> = ({
  data,
}) => {
  const [dataType] = useRecoilState(percentileTypes)

  if (isNullOrUndefined(data)) {
    return null
  }
  return (
    <CellFormatWrapper>
      {['0.1', '0.5', '0.9'].map(key => (
        <Text
          key={key}
          css={
            !data.symbol
              ? { fontSize: '$2', background: '$subTbHeaderBgColor' }
              : { fontSize: '$3' }
          }
        >
          {!data.symbol
            ? (data?.[key] as string)
            : toPercentStr(
                (data?.[dataType] as PercentilePercentData)?.[key],
                2
              )}
        </Text>
      ))}
    </CellFormatWrapper>
  )
}
