import 'react-datepicker/dist/react-datepicker.css'

import { styled } from '@stitches/react'
import { addDays, subDays } from 'date-fns'
import dayjs from 'dayjs'
import React, { forwardRef, Ref, useCallback, useMemo, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { toast } from 'react-toastify'

import customFetch from '../../api/customFetch'
import { useAuth } from '../../hooks'
import Button from '../Button'
import Modal from '../Modal'

type CustomInputProps = {
  value?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  placeholder?: string
}

const StyledButton = styled('button', {
  width: '100%',
  minHeight: '40px',
  border: 'none',
  borderBottom: '1px solid $primary',
  background: 'transparent',
  fontSize: '16px',
  cursor: 'pointer',
  fontWeight: '600',
})

const ButtonContainer = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  minWidth: '435px',

  '.react-datepicker-wrapper': {
    flex: '1',
  },
  '.react-datepicker-popper': {
    width: '328px',
  },
  '.react-datepicker': {
    backgroundColor: '$background',
    color: '$text',
  },
  '.react-datepicker__header, .react-datepicker__time': {
    backgroundColor: '$background',
  },
  '.react-datepicker__time': {
    backgroundColor: '$background !important',
  },
  '.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name, .react-datepicker-time__header, .react-datepicker__current-month':
    {
      color: '$text',
    },
  '.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover':
    {
      backgroundColor: '$primary !important',
    },

  '.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover':
    {
      backgroundColor: '$primary !important',
    },

  '.react-datepicker__day--selected, .react-datepicker__time-list-item--selected':
    {
      backgroundColor: '$primary !important',
    },
  '.react-datepicker__time-container, .react-datepicker, .react-datepicker__header, .react-datepicker__month-container':
    {
      borderColor: '$borderColor',
    },

  '.react-datepicker__day--disabled': {
    color: '$disabledText !important',
    backgroundColor: 'transparent !important',
  },
})

type ExportDataPrarms = {
  startTimestamp?: number
  endTimestamp?: number
  userName: string
}

const extractPercentile = async (data: ExportDataPrarms) => {
  const res = await customFetch<{ result?: string; error?: string }>(
    '/Percentile/RequestPercentileData',
    {
      data,
      method: 'POST',
    }
  )

  return res
}

export default function PercentiledDateModal() {
  const { accountInfo } = useAuth()
  const [loading, setLoading] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const CustomInput: React.FC<CustomInputProps> = useMemo(
    () =>
      forwardRef((props, ref) => {
        const { value, onClick, placeholder } = props
        return (
          <StyledButton
            className="example-custom-input"
            onClick={onClick}
            ref={ref as Ref<HTMLButtonElement>}
            css={!value ? { color: '$subtleText' } : { color: '$text' }}
          >
            {value || placeholder}
          </StyledButton>
        )
      }),
    []
  )

  const close = useCallback((visible?: boolean) => {
    setStartDate(null)
    setEndDate(null)
    setModalVisible(visible || false)
  }, [])

  const submit = useCallback(async () => {
    let startTimestamp
    let endTimestamp
    if (startDate) {
      startTimestamp = dayjs(startDate).valueOf()
    }
    if (endDate) {
      endTimestamp = dayjs(endDate).valueOf()
    }

    const payload = {
      startTimestamp,
      endTimestamp,
      userName: accountInfo?.username,
    }
    setLoading(true)
    try {
      const res = await extractPercentile(payload as ExportDataPrarms)
      if (res.result === 'OK') {
        toast.success('Exported successfully, please check your email')
        close()
      } else {
        toast.error(res.error)
      }
    } catch (error) {
      toast.error(JSON.stringify(error))
    } finally {
      setLoading(false)
    }
  }, [startDate, endDate, accountInfo, close])

  return (
    <Modal
      show={modalVisible}
      onOpenChange={close}
      trigger={<Button onClick={() => setModalVisible(true)}>Extract</Button>}
    >
      <Modal.Title>Extract Data</Modal.Title>
      <ButtonContainer>
        <ReactDatePicker
          selected={startDate}
          showPopperArrow={false}
          showTimeSelect
          placeholderText="Start Date(Optional)"
          onChange={date => setStartDate(date)}
          customInput={<CustomInput />}
          timeFormat="HH:mm"
          timeIntervals={15}
          dateFormat="MMM dd, yyyy HH:mm"
          showDisabledMonthNavigation
          excludeDateIntervals={[
            {
              start: endDate ? addDays(endDate, 1) : new Date(),
              end: addDays(new Date(), 10000000),
            },
          ]}
        />
        -
        <ReactDatePicker
          selected={endDate}
          showPopperArrow={false}
          showTimeSelect
          placeholderText="End Date(Optional)"
          onChange={date => setEndDate(date)}
          customInput={<CustomInput />}
          timeIntervals={15}
          timeFormat="HH:mm"
          dateFormat="MMM dd, yyyy HH:mm"
          showDisabledMonthNavigation
          excludeDateIntervals={[
            {
              start: startDate ? new Date(0) : new Date(),
              end: startDate
                ? subDays(startDate, 1)
                : addDays(new Date(), 10000000),
            },
          ]}
        />
      </ButtonContainer>
      <Modal.ButtonGroup>
        <Button
          onClick={() => {
            setStartDate(null)
            setEndDate(null)
          }}
        >
          Reset
        </Button>
        <Button onClick={submit} disabled={loading}>
          {loading ? 'Extracting' : 'Extract'}
        </Button>
      </Modal.ButtonGroup>
    </Modal>
  )
}
