import DataTable, { DataTableColumns } from '../../../components/DataTable/base'
import { FutureSwap } from '../../../types/business'
import { formatTs, thousandSeparate } from '../../../utils'

type ETFTradingPairTableProps = {
  title: string
  data?: FutureSwap[]
}

const columns: DataTableColumns<FutureSwap> = [
  {
    label: 'Instrument',
    render: row => row.instrumentId,
  },
  { label: 'Exchange', render: row => row.exchange },
  {
    label: 'Bid Size',
    cellStyles: { padding: 0 },
    render: row =>
      row.bidSize >= 0
        ? thousandSeparate(row.bidSize)
        : row.bidSize.toPrecision(5),
    width: '100px',
  },
  {
    label: 'Bid',
    cellStyles: { padding: 0 },
    render: row => thousandSeparate(row.bid),
    width: '100px',
  },
  {
    label: 'Ask',
    cellStyles: { padding: 0 },
    render: row => thousandSeparate(row.ask),
    width: '100px',
  },
  {
    label: 'Ask Size',
    cellStyles: { padding: 0 },
    render: row =>
      row.askSize >= 0
        ? thousandSeparate(row.askSize)
        : row.askSize.toPrecision(5),
    width: '100px',
  },
  {
    label: 'Prem Bid',
    cellStyles: { padding: 0 },
    render: row => row.futPremiumBid,
  },
  {
    label: 'Prem Ask',
    cellStyles: { padding: 0 },
    render: row => row.futPremiumAsk,
  },
  { label: 'APY', render: row => row.apy },
  {
    label: 'Timestamp',
    render: row => formatTs(row.timestamp || ''),
  },
]

const ETFTradingPairTable: React.FC<ETFTradingPairTableProps> = ({
  title,
  data = [],
}) => {
  return (
    <DataTable
      rowKey="instrumentId"
      title={title}
      columns={columns}
      data={data}
    />
  )
}

export default ETFTradingPairTable
