import { atom } from 'recoil'

import { ATOM_KEY } from '../constants'
import { AccountInfo } from '../types'
import { sessionStorageEffect } from './utils'

export const accountInfoState = atom<AccountInfo | null>({
  key: ATOM_KEY.AccountInfo,
  default: null,
  effects: [sessionStorageEffect(ATOM_KEY.AccountInfo)],
})
