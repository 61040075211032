import { v4 as uuid } from 'uuid'

import webSocketClient from '../../api/webSocketClient'
import { CoinPairInfo, CoinPrice } from '../../types/business'

export const setFilter = (
  type: 'maker_commission' | 'taker_commission' | 'min_size',
  item: CoinPrice | CoinPairInfo,
  value: number
) => {
  const request = {
    type: type === 'min_size' ? 'setSizeFilter' : 'setCommission',
    requestId: uuid(),
    data: [
      {
        // type:item.type,
        exchange_raw: item.exchange_raw,
        id: item.id,
        common_id: item.common_id,
        [type]: value,
      },
    ],
  }
  webSocketClient.send(JSON.stringify(request))
}
