import {
  ContractType,
  DerivativeEx,
  ExchangeDetailsConfig,
} from '../../types/business'
import { OVERVIEW } from '../dataCategory'

type SpotsStreamData = {
  columns: string[] // available exchanges
  allData: DerivativeEx[] // data for the current quarter
  tabName: string
}

const tabs = ['Current']

export const SpotsConfig: ExchangeDetailsConfig<SpotsStreamData> = {
  title: 'Spots',
  dataCategory: OVERVIEW.Spots,
  tabs: {
    defaultValue: tabs[0],
    labels: tabs,
  },
  tables: [
    {
      type: 'DerivativeEx',
      dataKey: 'allData',
      tableProps: {
        contractType: ContractType.Spot,
      },
      tablePropsExtractor: data => ({
        columnLabels: data.columns,
      }),
      tab: tabs[0],
    },
  ],
}
