import { styled } from '../../styles'

export const RawInput = styled('input', {
  padding: '$small $medium',
  width: '100%',
  borderRadius: '$medium',
  outline: 'none',
  backgroundColor: '$background',
  border: '2px solid $borderColor',
  color: '$text',
  caretColor: '$text',
  '&:hover:enabled, &:focus:enabled': {
    borderColor: '$primary',
  },
  '&:focus:enabled': {
    focusOutline: '$focusOutlineColor',
  },
  '&::placeholder': {
    color: '$subtleText',
  },
  '&:disabled': {
    backgroundColor: '$disabledBackground',
  },
  variants: {
    invalid: {
      true: {
        borderColor: '$warn',
        '&:hover:enabled, &:focus:enabled': {
          borderColor: '$warn',
        },
      },
    },
    size: {
      small: {
        padding: '$min $tiny',
      },
      medium: {
        padding: '$small $medium',
      },
    },
  },
  defaultVariants: {
    size: 'medium',
  },
})
