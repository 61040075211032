import { styled } from '../../styles'

const Label = styled('label', {
  backgroundColor: 'transparent',
  padding: '0 $2',
  color: '$text',
  fontWeight: 'bold',
  fontSize: '$small',
})

export default Label
