export const msSleep = (ms: number): Promise<TimerHandler> =>
  new Promise(resolve => setTimeout(resolve, ms))

export const noFalsyJoin = (data: Array<unknown>, separator: string) => {
  return data.filter(el => el).join(separator)
}

export const isNullOrUndefined = (val: unknown): val is null | undefined => {
  return val === null || val === undefined
}

export const downloadFile = (data: Blob, filename: string) => {
  const url = window.URL.createObjectURL(data)
  const anchor = document.createElement('a')
  anchor.href = url
  anchor.download = filename
  anchor.click()

  window.URL.revokeObjectURL(url)
  anchor.remove()
}
