import { FxRate } from '../../types/business'
import DataTable, { DataTableColumns } from './base'

type FxRateTableProps = {
  title?: string
  data?: FxRate[]
}

type TransformedFxRateData = Record<string, number>[]

// merge all FxRate into one object and return the wrapped object in array
const transformData = (data: FxRate[]): TransformedFxRateData => {
  const singleRecord = data.reduce((acc, item) => {
    return {
      ...acc,
      [item.currency]: item.rate,
    }
  }, {})

  return [singleRecord]
}

export const FxRateTable: React.FC<FxRateTableProps> = ({
  title,
  data = [],
}) => {
  // every symbol become a column
  const columns: DataTableColumns = data?.map(item => ({
    label: item.currency,
    width: '120px',
    render: () => item.rate,
  }))

  return (
    <DataTable
      rowKey=""
      title={title ?? 'FX Rates (vs. USD)'}
      columns={columns}
      data={transformData(data)}
    />
  )
}
