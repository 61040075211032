import { COIN, EXCHANGE, OVERVIEW } from './dataCategory'

const toPath = <T>(obj: Record<string, string>, prefix: string) => {
  const result = Object.entries(obj).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: `${prefix}/${value}`,
    }
  }, {})

  return result as T
}

export const SEARCHABLE_PATH = {
  Admin: '/admin',
  ETF: '/etf',
  ...toPath<typeof OVERVIEW>(OVERVIEW, '/overview'),
  ...toPath<typeof EXCHANGE>(EXCHANGE, '/exchange'),
  ...toPath<typeof COIN>(COIN, '/coin'),
}

// Check the type of PATH to get the complete mapping
export const PATH = {
  Landing: '/',
  Login: '/login',
  ...SEARCHABLE_PATH,
}
