import { styled } from '../styles'

const Text = styled('p', {
  margin: 0,
  padding: 0,
  fontSize: '$medium',
})

Text.displayName = 'Text'

export default Text
