import { FutureDeliveryInfo } from '../../types/business'
import DataTable, { DataTableColumns } from './base'

type FutureDeliveryTableProps = {
  data?: FutureDeliveryInfo[]
}

const columns: DataTableColumns<FutureDeliveryInfo> = [
  {
    label: 'Symbol',
    render: row => row.symbol,
  },
  {
    label: 'Delivery Date',
    render: row => row.deliveryDate,
  },
  {
    label: 'Left',
    render: row => row.left,
  },
]

export const FutureDeliveryTable: React.FC<FutureDeliveryTableProps> = ({
  data = [],
}) => {
  return (
    <DataTable
      rowKey="symbol"
      title="Future Delivery Date"
      columns={columns}
      data={data}
    />
  )
}
