import { toNumber } from 'lodash-es'
import { useState } from 'react'
import { BiEdit, BiRefresh, BiSave } from 'react-icons/bi'

import { styled } from '../../../styles'
import IconButton from '../../IconButton'
import { RawInput } from '../../Inputs/RawInput'

type NumberEditCellProps = {
  defaultValue?: string
  disabled?: boolean
  onChange: (val: number) => void
}

const Wrapper = styled('div', {
  flexRowCenter: 'flex-start',
})

const ButtonGroup = styled('div', {
  flexRowCenter: 'space-between',
  flex: '0 1 50px',
  color: '$text',
})

const StyledRawInput = styled(RawInput, {
  padding: '$min',
  flex: 1,
  marginRight: '$min',
})

export const NumberEditCell: React.FC<NumberEditCellProps> = ({
  defaultValue = '0',
  disabled = false,
  onChange,
}) => {
  const [value, setValue] = useState<string>(defaultValue)
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const handleResetDefault = () => {
    setValue(defaultValue)
    setIsEditing(false)
    onChange(toNumber(defaultValue))
  }

  const handleSave = () => {
    setIsEditing(false)
    onChange(toNumber(value))
  }

  return (
    <Wrapper>
      <StyledRawInput
        type="number"
        value={value}
        disabled={!isEditing || disabled}
        onChange={e => setValue(e.target.value)}
      />
      <ButtonGroup>
        {!isEditing ? (
          <IconButton
            icon={<BiEdit />}
            size="small"
            onClick={() => setIsEditing(true)}
            title="edit"
            disabled={disabled}
          />
        ) : (
          <>
            <IconButton
              icon={<BiSave />}
              size="small"
              onClick={handleSave}
              title="save"
              disabled={disabled}
            />
            <IconButton
              icon={<BiRefresh />}
              size="small"
              onClick={handleResetDefault}
              title="reset"
              disabled={disabled}
            />
          </>
        )}
      </ButtonGroup>
    </Wrapper>
  )
}
