import { ETFTMinusData } from '../../../types/etf'
import { thousandSeparate, toPercentStr } from '../../../utils'
import CoinTable, { FieldConfig } from './CoinTable'

type ETFCMEReplicationDataTableProps = {
  data: Record<string, ETFTMinusData>
}

const fieldNameMap: Partial<Record<keyof ETFTMinusData, FieldConfig>> = {
  navFairValueLive: {
    label: 'ETF NAV Fair Value Live',
    type: 'number',
    editable: false,
    format: (val: number) => val.toFixed(4),
  },
  navPerUnitInUsd: {
    label: 'ETF NAV T-1',
    type: 'number',
    editable: false,
    format: (val: number) => val.toFixed(4),
  },
  cmeFutureFirstMonthLive: {
    label: 'CME Future 1st Month Live',
    type: 'number',
    editable: false,
    format: (val: number) => thousandSeparate(val),
  },
  cmeFutureFirstMonthTMinus: {
    label: 'CME Future 1st Month T-1',
    type: 'number',
    editable: true,
    format: (val: number) => thousandSeparate(val),
  },
  cmeFutureSecondMonthLive: {
    label: 'CME Future 2nd Month Live',
    type: 'number',
    editable: false,
    format: (val: number) => thousandSeparate(val),
  },
  cmeFutureSecondMonthTMinus: {
    label: 'CME Future 2nd Month T-1',
    type: 'number',
    editable: true,
    format: (val: number) => thousandSeparate(val),
  },
  firstMonthPr: {
    label: '1st Month PR',
    type: 'number',
    editable: false,
    format: (val: number) => toPercentStr(val, 2),
  },
  secondMonthPr: {
    label: '2nd Month PR',
    type: 'number',
    editable: false,
    format: (val: number) => toPercentStr(val, 2),
  },
}

const ETFCMEReplicationDataTable: React.FC<ETFCMEReplicationDataTableProps> = ({
  data,
}) => {
  return (
    <CoinTable
      subtitle="CME Replication Data"
      data={data}
      fieldNameMap={fieldNameMap}
    />
  )
}

export default ETFCMEReplicationDataTable
