import { createStitches, CSSProperties } from '@stitches/react'

import { darkThemeColors, lightThemeColors } from './colorTheme'

export const {
  styled,
  css,
  globalCss,
  keyframes,
  theme,
  createTheme,
  config: themeConfig,
} = createStitches({
  theme: {
    colors: lightThemeColors,
    fonts: {
      primary: "'Poppins', sans-serif",
    },
    fontSizes: {
      1: '0.5rem',
      2: '0.75rem',
      3: '0.875rem',
      4: '1rem',
      5: '1.25rem',
      6: '1.5rem',
      7: '1.75rem',
      8: '2rem',
      tiny: '0.7rem',
      small: '0.875rem',
      medium: '1rem',
      large: '1.5rem',
      veryLarge: '2rem',
    },
    space: {
      1: '4px',
      2: '8px',
      3: '12px',
      4: '16px',
      5: '20px',
      6: '24px',
      7: '28px',
      8: '32px',
      16: '64px',
      min: '4px',
      tabelCellPadding: '4px',
      tiny: '8px',
      small: '12px',
      medium: '16px',
      large: '24px',
    },
    sizes: {
      1: '4px',
      2: '8px',
      3: '12px',
      4: '16px',
      5: '20px',
      6: '24px',
      7: '28px',
      8: '32px',
      small: '12px',
      medium: '16px',
      large: '24px',
    },
    radii: {
      small: '4px',
      medium: '8px',
      large: '16px',
      semiCircle: '1000px',
      round: '50%',
    },
    zIndices: {
      navBar: 1100,
      modal: 1300,
      modalContent: 1310,
      tooltip: 1500,
      noti: 1600,
    },
  },
  utils: {
    p: (value: CSSProperties['padding']) => ({
      padding: value,
    }),
    px: (value: CSSProperties['padding']) => ({
      paddingRight: value,
      paddingLeft: value,
    }),
    py: (value: CSSProperties['padding']) => ({
      paddingTop: value,
      paddingBottom: value,
    }),
    m: (value: CSSProperties['margin']) => ({
      margin: value,
    }),
    mx: (value: CSSProperties['margin']) => ({
      marginLeft: value,
      marginRight: value,
    }),
    my: (value: CSSProperties['margin']) => ({
      marginTop: value,
      marginBottom: value,
    }),
    flexRowCenter: (value: CSSProperties['justifyContent']) => ({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      ...(value ? { justifyContent: value } : {}),
    }),
    barBetween: (value: CSSProperties['padding']) => ({
      '& > *:after': {
        content: '|',
        padding: value,
      },
      '& > *:last-child:after': {
        content: '',
      },
    }),
    // eslint-disable-next-line unused-imports/no-unused-vars
    focusOutline: (color?: CSSProperties['color']) => {
      const finalColor = color || 'transparent'
      return {
        outline: `${finalColor} solid 2px`,
      }
    },
  },
})

export const darkTheme = createTheme('dark-theme', {
  colors: darkThemeColors,
})

export type ThemeConfig = typeof themeConfig
