import {
  createGroupRouteItem,
  createSingleRouteItem,
  GroupRouteItem,
  SingleRouteItem,
} from '../types'
import { PATH } from './path'

export const ROUTE_ITEMS: Array<SingleRouteItem | GroupRouteItem> = [
  createGroupRouteItem('Overview', [
    createSingleRouteItem('Futures', PATH.Futures),
    createSingleRouteItem('Swaps', PATH.Swaps),
    createSingleRouteItem('SimpleEarn', PATH.SimpleEarn),
    createSingleRouteItem('Spots', PATH.Spots),
    createSingleRouteItem('DerivBTC', PATH.DerivBTC),
    createSingleRouteItem('DerivETH', PATH.DerivETH),
  ]),
  createGroupRouteItem('Exchanges', [
    createSingleRouteItem('Binance', PATH.Binance),
    createSingleRouteItem('Bitfinex', PATH.Bitfinex),
    createSingleRouteItem('BitMex', PATH.BitMex),
    createSingleRouteItem('Bybit', PATH.Bybit),
    createSingleRouteItem('CoinFlex', PATH.CoinFlex),
    createSingleRouteItem('DYDX', PATH.DYDX),
    createSingleRouteItem('FTX', PATH.Ftx),
    createSingleRouteItem('KuCoin', PATH.KuCoin),
    createSingleRouteItem('AscendEX', PATH.AscendEX),
    createSingleRouteItem('CryptoCom', PATH.CryptoCom),
    createSingleRouteItem('Deribit', PATH.Deribit),
    createSingleRouteItem('GateIO', PATH.GateIO),
    createSingleRouteItem('Huobi', PATH.Huobi),
    createSingleRouteItem('Kraken', PATH.Kraken),
    createSingleRouteItem('OKEx', PATH.OKEx),
    createSingleRouteItem('DeFi', PATH.DeFi),
    createSingleRouteItem('Stable', PATH.Stable),
  ]),
  createGroupRouteItem('Coins', [
    createSingleRouteItem('BTC', PATH.BTC),
    createSingleRouteItem('ETH', PATH.ETH),
    createSingleRouteItem('LTC', PATH.LTC),
    createSingleRouteItem('BCH', PATH.BCH),
    createSingleRouteItem('XRP', PATH.XRP),
  ]),
  createSingleRouteItem('ETF', PATH.ETF),
  createSingleRouteItem('Admin', PATH.Admin),
]
