import DataTable, { DataTableColumns } from '../../../components/DataTable/base'
import { ETFPriceInfo } from '../../../types/etf'

type ETFData = {
  fieldName: string
  BTC: number
  ETH: number
}

type ETFLivePricesTableProps = {
  data: Record<string, ETFPriceInfo>
}

const fieldNameMap: Partial<Record<keyof ETFPriceInfo, string>> = {
  etfPriceLive: 'CSOP ETF',
  cmeReplicationPrice: 'CME Replication ETF',
}

const columns: DataTableColumns<ETFData> = [
  {
    label: '',
    width: '200px',
    render: row => row.fieldName,
  },
  {
    label: 'CSOP BTC FUTURES ETF',
    width: '200px',
    render: row => row.BTC?.toFixed(4),
  },
  {
    label: 'CSOP ETH FUTURES ETF',
    width: '200px',
    render: row => row.ETH?.toFixed(4),
  },
]

const transformData = (data: Record<string, ETFPriceInfo>): ETFData[] => {
  return (Object.keys(fieldNameMap) as (keyof ETFPriceInfo)[]).map(
    fieldName => {
      return {
        fieldName: fieldNameMap[fieldName] as string,
        BTC: data.BTC?.[fieldName] as number,
        ETH: data.ETH?.[fieldName] as number,
      }
    }
  )
}

const ETFLivePricesTable: React.FC<ETFLivePricesTableProps> = ({ data }) => {
  return (
    <DataTable
      rowKey=""
      title="ETF Live Prices"
      columns={columns}
      data={transformData(data)}
    />
  )
}

export default ETFLivePricesTable
