import { styled } from '../styles'

const Kbd = styled('kbd', {
  fontSize: '$tiny',
  fontWeight: 'bold',
  padding: '$min',
  borderStyle: 'solid',
  borderColor: '$borderColor',
  borderWidth: '1px 1px 3px',
  borderRadius: '$small',
  backgroundColor: '$primary',
  display: 'inline-block',
})

export default Kbd
