/* eslint-disable camelcase */
import { maxBy, minBy } from 'lodash-es'
import { useCallback, useMemo, useState } from 'react'

import { CoinPrice } from '../../types/business'
import { convertToShortForm } from '../../utils'
import dayjs from '../../utils/dayjs'
import DataTable, { DataTableColumns } from './base'
import { CheckboxCell, NumberEditCell } from './components'
import { setFilter } from './utils'

type CoinPriceTableProps = {
  data: CoinPrice[]
}

type BestMarketInfo = {
  bestBidExchange: string
  bid_price: string
  bid_price_raw: string
  bid_size: string
  bestAskExchange: string
  ask_price: string
  ask_price_raw: string
  ask_size: string
  spread: number | string
}

const bestMarketColumns: DataTableColumns<BestMarketInfo> = [
  {
    key: 'highestBidPriceTradeableExchange',
    label: 'Exchange',
    render: row => row.bestBidExchange,
    width: '100px',
  },
  {
    label: 'Bid Size',
    render: row => row.bid_size,
  },
  {
    label: 'Bid',
    render: row => row.bid_price,
  },
  {
    label: 'Bid Raw',
    render: row => row.bid_price_raw,
  },
  {
    label: 'Ask Raw',
    render: row => row.ask_price_raw,
  },
  {
    label: 'Ask',
    render: row => row.ask_price,
  },
  {
    label: 'Ask Size',
    render: row => row.ask_size,
  },
  {
    key: 'lowestAskPriceTradeableExchange',
    label: 'Exchange',
    render: row => row.bestAskExchange,
    width: '100px',
  },
  {
    label: 'Spread',
    render: row => row.spread,
  },
]

const transformData = (
  data: CoinPrice[],
  filterBidEx: string[] = [],
  filterAskEx: string[] = []
): BestMarketInfo[] => {
  const highestBidOrder = maxBy(
    data.filter(el => !filterBidEx.includes(el.exchange)),
    'bid_price'
  )
  const lowestAskOrder = minBy(
    data.filter(
      el => Boolean(el.ask_price) && !filterAskEx.includes(el.exchange)
    ), // zero value is not considered here
    'ask_price'
  )
  const spread =
    highestBidOrder && lowestAskOrder
      ? `${(
          ((lowestAskOrder.ask_price - highestBidOrder.bid_price) /
            highestBidOrder.bid_price) *
          100
        ).toFixed(3)}%`
      : 'N/A'

  return [
    {
      bestBidExchange: highestBidOrder?.exchange || 'N/A',
      bid_size: highestBidOrder?.bid_size.toFixed(4) || 'N/A',
      bid_price: highestBidOrder?.bid_price.toFixed(4) || 'N/A',
      bid_price_raw: highestBidOrder?.bid_price_raw.toFixed(4) || 'N/A',
      ask_price_raw: lowestAskOrder?.ask_price_raw.toFixed(4) || 'N/A',
      ask_price: lowestAskOrder?.ask_price.toFixed(4) || 'N/A',
      ask_size: lowestAskOrder?.ask_size.toFixed(4) || 'N/A',
      bestAskExchange: lowestAskOrder?.exchange || 'N/A',
      spread,
    },
  ]
}

export const CoinPriceTable: React.FC<CoinPriceTableProps> = ({ data }) => {
  const [bestBidFilter, setBestBidFilter] = useState<string[]>([])
  const [bestAskFilter, setBestAskFilter] = useState<string[]>([])

  const toggleTA = useCallback(
    (exchange: string, type: 'bid' | 'ask', check: boolean) => {
      const setter = type === 'bid' ? setBestBidFilter : setBestAskFilter
      if (!check) {
        setter(val => [...val, exchange])
      } else {
        setter(val => val.filter(el => el !== exchange))
      }
    },
    []
  )

  const columns: DataTableColumns<CoinPrice> = useMemo(
    () => [
      {
        label: 'Exchange',
        render: row => row.exchange,
      },
      {
        label: 'Currency',
        render: row => row.fixedCurrency,
      },
      {
        key: 'includeInBidBest',
        label: 'TA',
        render: row => (
          <CheckboxCell
            defaultChecked
            onChange={checked => toggleTA(row.exchange, 'bid', checked)}
          />
        ),
      },
      {
        label: 'Bid Size',
        render: row => row.bid_size.toFixed(4),
      },
      {
        label: 'Bid',
        render: row => row.bid_price.toFixed(3),
      },
      {
        label: 'Ask',
        render: row => row.ask_price.toFixed(3),
      },
      {
        label: 'Ask Size',
        render: row => row.ask_size.toFixed(4),
      },
      {
        key: 'includeInAskBest',
        label: 'TA',
        render: row => (
          <CheckboxCell
            defaultChecked
            onChange={checked => toggleTA(row.exchange, 'ask', checked)}
          />
        ),
      },
      {
        key: 'span0',
        label: '',
        isEmpty: true,
        render: () => '',
        width: '30px',
      },
      {
        label: 'CCY',
        render: row => row.currency,
      },
      {
        label: 'Raw Bid',
        render: row => row.bid_price_raw,
      },
      {
        label: 'Raw Ask',
        render: row => row.ask_price_raw,
      },
      {
        label: '24H Volume',
        render: row => convertToShortForm(row.h24Volume?.replaceAll(',', '')),
      },
      {
        label: 'Timestamp',
        render: row => dayjs(row.timestamp).format('HH:mm:ss'),
      },
      {
        key: 'span1',
        label: '',
        isEmpty: true,
        render: () => '',
        width: '30px',
      },
      {
        label: 'Maker %',
        width: '170px',
        render: row => (
          <NumberEditCell
            onChange={val => setFilter('maker_commission', row, val)}
          />
        ),
      },
      {
        label: 'Taker %',
        width: '170px',
        render: row => (
          <NumberEditCell
            onChange={val => setFilter('taker_commission', row, val)}
          />
        ),
      },
      {
        label: 'Min Size %',
        width: '170px',
        render: row => (
          <NumberEditCell onChange={val => setFilter('min_size', row, val)} />
        ),
      },
    ],
    [toggleTA]
  )

  return (
    <>
      <DataTable
        rowKey="id"
        title="Physical Prices"
        columns={columns}
        data={data}
      />
      <DataTable
        rowKey=""
        title="Best Physical Market (Tradeable)"
        columns={bestMarketColumns}
        data={transformData(data, bestBidFilter, bestAskFilter)}
      />
      <DataTable
        rowKey=""
        title="Best Physical Market (All)"
        columns={bestMarketColumns}
        data={transformData(data)}
      />
    </>
  )
}
