import { Outlet } from 'react-router-dom'

import Header from './Header'
import Main from './Main'

const AppLayout: React.FC = () => {
  return (
    <>
      <Header />
      <Main>
        <Outlet />
      </Main>
    </>
  )
}

export default AppLayout
