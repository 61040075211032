import {
  ExchangeDetailsConfig,
  FundingRateAggregation,
  FutureDeliveryInfo,
  FutureSwap,
  FxRate,
  Index,
} from '../../types/business'
import { EXCHANGE } from '../dataCategory'

type HuobiStreamData = {
  fxRates: FxRate[]
  indexData: Index[]
  futureInfo: FutureDeliveryInfo[]
  fundingAggInfo: FundingRateAggregation[]
  futData: FutureSwap[]
  tabName: string
}

const tabs = ['All Derivs', 'Funding Rate', 'Misc']

export const HuobiConfig: ExchangeDetailsConfig<HuobiStreamData> = {
  title: 'Huobi',
  dataCategory: EXCHANGE.Huobi,
  tabs: {
    defaultValue: tabs[0],
    labels: tabs,
  },
  tables: [
    {
      type: 'FutureSwap',
      dataKey: 'futData',
      tableProps: {
        title: 'HuobiPro vs All Derivs (Taker / Taker Premium)',
      },
      tab: tabs[0],
    },
    {
      type: 'FundingRate',
      dataKey: 'fundingAggInfo',
      tableProps: null,
      tab: tabs[1],
    },
    {
      type: 'FxRate',
      dataKey: 'fxRates',
      tableProps: null,
      tab: tabs[2],
    },
    {
      type: 'Index',
      dataKey: 'indexData',
      tableProps: null,
      tab: tabs[2],
    },
    {
      type: 'FutureDelivery',
      dataKey: 'futureInfo',
      tableProps: null,
      tab: tabs[2],
    },
  ],
}
