import { styled } from '../../../styles'
import { AllStableExData } from '../../../types/business'
import { isNullOrUndefined, thousandSeparate } from '../../../utils'
import Text from '../../Text'
import Tooltip from '../../Tooltip'

type StableExDataCellProps = {
  data: AllStableExData | null
}

export const AllExCellFormatWrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  '& > *': {
    border: '1px solid $borderColor',
    padding: '0 $min',
  },
})

export const StableExDataCell: React.FC<StableExDataCellProps> = ({ data }) => {
  if (isNullOrUndefined(data)) {
    return null
  }
  // color="#41f2ad"
  // color="#fb8d8d"
  const bidPrice = data?.bid ?? 0
  const askPrice = data?.ask ?? 1
  return (
    <AllExCellFormatWrapper>
      <Tooltip
        message={`Volume in 24H: ${thousandSeparate(data?.volume24H || 0)}`}
      >
        <Text
          css={{ width: 80, ...(bidPrice > 1 ? { color: '$bid' } : {}) }}
          as="span"
        >
          {data?.bid}
        </Text>
      </Tooltip>
      <Tooltip
        message={`Volume in 24H: ${thousandSeparate(data?.volume24H || 0)}`}
      >
        <Text
          css={{
            width: 80,
            ...(askPrice > 0 && askPrice < 1 ? { color: '$ask' } : {}),
          }}
          as="span"
        >
          {data?.ask}
        </Text>
      </Tooltip>
    </AllExCellFormatWrapper>
  )
}
