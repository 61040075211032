import {
  Close,
  Content,
  Overlay,
  Portal,
  Root,
  Trigger,
} from '@radix-ui/react-dialog'
import { forwardRef } from 'react'
import { AiOutlineClose } from 'react-icons/ai'

import { styled } from '../styles'
import IconButton from './IconButton'

type DialogProps = {
  show?: boolean
  trigger?: React.ReactNode
  onOpenChange?: (open: boolean) => void
  children?: React.ReactNode
}

const StyledContent = styled(Content, {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '$background',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '800px',
  minWidth: '400px',
  maxHeight: '80vh',
  borderRadius: '$large',
  padding: '$medium',
  boxShadow: '0 0 10px $colors$shadowColor',
  zIndex: '$modalContent',
})

const StyledOverlay = styled(Overlay, {
  backgroundColor: '$overlayColor',
  position: 'fixed',
  inset: 0,
  zIndex: '$modal',
  // '@media (prefers-reduced-motion: no-preference)': {
  //   animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards`,
  // },
})

const ModalTitle = styled('h3', {
  lineHeight: '1.5',
  margin: '0 $medium $medium 0',
  padding: 0,
  fontWeight: 'bold',
  fontSize: '$7',
})

const ButtonGroup = styled('div', {
  width: '100%',
  flexRowCenter: 'center',
  margin: '$medium 0 0',
  fontSize: '$medium',
  gap: '$medium',
})

const RawCloseButton = forwardRef<HTMLButtonElement>((props, ref) => {
  return (
    <Close asChild>
      <IconButton icon={<AiOutlineClose />} size="small" {...props} ref={ref} />
    </Close>
  )
})

const CloseButton = styled(RawCloseButton, {
  position: 'absolute',
  top: '$tiny',
  right: '$tiny',
})

const Modal: React.FC<DialogProps> & {
  Title: typeof ModalTitle
  CloseButton: typeof CloseButton
  Close: typeof Close
  ButtonGroup: typeof ButtonGroup
} = ({ show, trigger, onOpenChange, children }) => {
  return (
    <Root open={show} onOpenChange={onOpenChange}>
      <Trigger asChild>{trigger}</Trigger>
      <Portal>
        <StyledOverlay />
        <StyledContent>
          <CloseButton />
          {children}
        </StyledContent>
      </Portal>
    </Root>
  )
}

Modal.Title = ModalTitle
Modal.CloseButton = CloseButton
Modal.Close = Close
Modal.ButtonGroup = ButtonGroup

export default Modal
