import {
  ExchangeDetailsConfig,
  FundingRateAggregation,
  FutureSwap,
  Index,
} from '../../types/business'
import { EXCHANGE } from '../dataCategory'

type CryptoComStreamData = {
  fundingAggInfo: FundingRateAggregation[]
  indexData: Index[]
  takerTakerData: FutureSwap[]
  tabName: string
}

const tabs = ['All Derivs', 'Funding Rate', 'Misc']

export const CryptoComConfig: ExchangeDetailsConfig<CryptoComStreamData> = {
  title: 'CryptoCom',
  dataCategory: EXCHANGE.CryptoCom,
  tabs: {
    defaultValue: tabs[0],
    labels: tabs,
  },
  tables: [
    {
      type: 'FutureSwap',
      dataKey: 'takerTakerData',
      tableProps: {
        title: 'CryptoCom vs All Derivs (Taker / Taker Premium)',
        dailySettlementCount: 24,
      },
      tab: tabs[0],
    },
    {
      type: 'FundingRate',
      dataKey: 'fundingAggInfo',
      tableProps: null,
      tab: tabs[1],
    },
    {
      type: 'Index',
      dataKey: 'indexData',
      tableProps: null,
      tab: tabs[2],
    },
  ],
}
