import { customFetchFile } from '../../api/customFetch'
import Box from '../../components/Box'
import Button from '../../components/Button'
import { CellFormatWrapper } from '../../components/DataTable/components'
import Text from '../../components/Text'
import {
  ContractType,
  DerivativeEx,
  DerivativeExV2,
  ExchangeDetailsConfig,
  OpenInterestChange,
} from '../../types/business'
import { downloadFile } from '../../utils'
import { EXCHANGE, OVERVIEW } from '../dataCategory'

type SwapsStreamData = {
  columns: string[] // available exchanges
  allData: DerivativeEx[] // data for the current quarter
  openInterestChanges: OpenInterestChange[] // for top 20 changes
  tabName: string
}

const tabs = ['CQ', 'Top 20 in Change of Open Interest']

const lessImportantExchanges = [EXCHANGE.AscendEX, EXCHANGE.CryptoCom]

export const SwapsConfig: ExchangeDetailsConfig<SwapsStreamData> = {
  title: 'Swaps',
  dataCategory: OVERVIEW.Swaps,
  tabs: {
    defaultValue: tabs[0],
    labels: tabs,
  },
  tables: [
    {
      type: 'DerivativeExV2',
      dataKey: null,
      transformData,
      tabeleHeader: () => {
        const download = async () => {
          const file = await customFetchFile('/Derivs/GetFiles?page=Swaps', {})
          downloadFile(file, 'swaps.xlsx')
        }
        return (
          <Box
            css={{
              flexRowCenter: 'flex-start',
              gap: '$medium',
              marginBottom: '$medium',
            }}
          >
            <Button onClick={download}>Extract</Button>
            <CellFormatWrapper css={{ textAlign: 'center' }}>
              <Text>1 Day Prem.</Text>
              <Text>3 Days Prem.</Text>
              <Text>10 Days Prem.</Text>
              <Text>30 Days Prem.</Text>
              <Text>Current APY</Text>
              <Text>Est. APY</Text>
              <Text>Volume 24H</Text>
              <Text>Open Interest</Text>
            </CellFormatWrapper>
          </Box>
        )
      },
      tableProps: {
        contractType: ContractType.Swap,
      },
      tablePropsExtractor: data => ({
        columnLabels: transformColumns(data.columns),
      }),
      tab: tabs[0],
    },
    {
      type: 'OpenInterestChange',
      dataKey: 'openInterestChanges',
      tableProps: null,
      tab: tabs[1],
    },
  ],
}

// put AscendEX and CryptoCom to the end of the array
function transformColumns(originals: string[]): string[] {
  return [
    ...originals.filter(el => !lessImportantExchanges.includes(el)),
    ...lessImportantExchanges,
  ]
}

/**
 * Transform {@link DerivativeEx} to {@link DerivativeExV2}
 */
function transformData(data: SwapsStreamData): DerivativeExV2[] {
  const derivativeExData: DerivativeExV2[] = []
  data.allData.forEach(el => {
    derivativeExData.push({
      ...el,
      datas: data.columns.reduce((acc, exchange, index) => {
        return {
          ...acc,
          [exchange]: el.datas[index],
        }
      }, {}),
    })
  })
  return derivativeExData
}
