import { DefiApyInfo, ExchangeDetailsConfig } from '../../types/business'
import { EXCHANGE } from '../dataCategory'

type DeFiStreamData = {
  datas: DefiApyInfo[]
  tabName: string
}

const tabs = ['All']

export const DeFiConfig: ExchangeDetailsConfig<DeFiStreamData> = {
  title: 'DeFi',
  dataCategory: EXCHANGE.DeFi,
  tabs: {
    defaultValue: tabs[0],
    labels: tabs,
  },
  tables: [
    {
      type: 'DefiApy',
      dataKey: 'datas',
      tableProps: null,
      tab: tabs[0],
    },
  ],
}
