import React, { useCallback, useMemo } from 'react'
import { useRecoilState } from 'recoil'

import { percentileDays, percentileTypes } from '../../stores/percentileData'
import { styled } from '../../styles'

const SwitchBox = styled('div', {
  display: 'flex',
  padding: '2px',
  border: '2px solid $primary',
  borderRadius: '$small',
})

const SwitchItem = styled('div', {
  padding: '2px 6px',
  cursor: 'pointer',
  minWidth: '50px',
  textAlign: 'center',
  variants: {
    actived: {
      true: {
        background: '$primary',
      },
      false: {},
    },
  },
})

export default function PercentileDataSwitch({
  comp,
  options,
}: {
  comp: 'day' | 'type'
  options: { label: string; value: string | number }[]
}) {
  const [dataType, setDataType] = useRecoilState(percentileTypes)
  const [dataDay, setDataDay] = useRecoilState(percentileDays)
  const handleChange = useCallback(
    (value: number | string) =>
      comp === 'day'
        ? setDataDay(value as number)
        : setDataType(value as string),
    [comp, setDataDay, setDataType]
  )

  const value = useMemo(
    () => (comp === 'day' ? dataDay : dataType),
    [dataType, dataDay, comp]
  )
  return (
    <SwitchBox>
      {options.map(item => (
        <SwitchItem
          actived={value === item.value}
          key={item.value}
          onClick={() => handleChange(item.value)}
        >
          {item.label}
        </SwitchItem>
      ))}
    </SwitchBox>
  )
}
