import { styled } from '../styles'

const Heading = styled('h3', {
  margin: '$small auto',
  padding: 0,
  fontWeight: 'normal',
  fontSize: '$large',

  variants: {
    size: {
      small: {
        fontSize: '$medium',
      },
      medium: {
        fontSize: '$large',
      },
      large: {
        fontSize: '$veryLarge',
      },
    },
  },
  defaultVariants: {
    size: 'medium',
  },
})

Heading.displayName = 'Heading'

export default Heading
