const getConnection = () => {
  const connection = new WebSocket(
    'wss://coinstream.pro:8004/ivybridge:Password123!'
  )

  return connection
}

const send = (message: string) => {
  const socketConn = getConnection()

  socketConn.onopen = () => {
    socketConn.send(message)
    socketConn.close()
  }
}

const webSocketClient = {
  send,
}

export default webSocketClient
