/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
import constate from 'constate'
import { isNumber } from 'lodash-es'
import { intersection, pick } from 'ramda'
import { useCallback, useState } from 'react'

import customFetch from '../../../api/customFetch'
import { SimpleApiResponse } from '../../../types'
import { ETFTMinusParameter } from '../../../types/etf'

const validateFieldList = [
  'cmeFutureFirstMonthTMinus',
  'cmeFutureSecondMonthTMinus',
  'navPerUnitInUsd',
  'sharesOutstanding',
  'futuresFirstMonth',
  'futuresSecondMonth',
  'pr',
]

const mapETFTMinusParameter = (data: ETFTMinusParameter) => {
  const result = {
    Coin: data.coin,
    CMEFutureFirstMonthTMinus: data.cmeFutureFirstMonthTMinus,
    CMEFutureSecondMonthTMinus: data.cmeFutureSecondMonthTMinus,
    NAVPerUnitInUsd: data.navPerUnitInUsd,
    SharesOutstanding: data.sharesOutstanding,
    FuturesFirstMonth: data.futuresFirstMonth,
    FuturesSecondMonth: data.futuresSecondMonth,
    PR: data.pr,
    CMEFutureFirstMonthTickerName: data.cmeFutureFirstMonthTickerName,
    CMEFutureSecondMonthTickerName: data.cmeFutureSecondMonthTickerName,
  }
  return result
}

const transformTMinusParameter = (
  data: Record<string, ETFTMinusParameter>
): Record<string, ETFTMinusParameter> => {
  const newData = {} as Record<string, ETFTMinusParameter>
  Object.keys(data).forEach(coin => {
    const param = data[coin]
    newData[coin] = {
      coin,
      cmeFutureFirstMonthTickerName: param.cmeFutureFirstMonthTickerName,
      cmeFutureSecondMonthTickerName: param.cmeFutureSecondMonthTickerName,
    } as ETFTMinusParameter
    Object.keys(param).forEach(key => {
      const value = (param as Record<string, any>)[key]
      if (
        validateFieldList.includes(key) &&
        value !== undefined &&
        value !== null &&
        value !== ''
      ) {
        if (key === 'pr') {
          newData[coin].pr = Number((value / 100).toFixed(4))
        } else {
          ;((newData[coin] as Record<string, any>)[key] as any) = Number(value)
        }
      }
    })
  })

  return newData
}

const validateTMinusParameter = (
  data: Record<string, ETFTMinusParameter>
): [boolean, string] => {
  try {
    Object.keys(data).forEach(coin => {
      // make sure all fields are non falsy value
      const pickObj = pick(validateFieldList, data[coin])

      const missingFields = validateFieldList.filter(
        el =>
          !intersection(validateFieldList, Object.keys(pickObj)).includes(el)
      )
      if (missingFields.length) {
        throw new Error(`Missing Field ${coin} ${missingFields.join(', ')}`)
      }
      Object.keys(pickObj).forEach(key => {
        if (!isNumber(pickObj[key as keyof ETFTMinusParameter])) {
          throw new Error(`Field ${coin} ${key} is not a number`)
        }
      })
    })
  } catch (e) {
    return [false, (e as Error).message]
  }

  return [true, '']
}

const saveTMinusParameter = async (
  data: Record<string, ETFTMinusParameter>
) => {
  const mappedData = Object.keys(data).reduce((acc, coin) => {
    return {
      ...acc,
      [coin]: mapETFTMinusParameter(data[coin]),
    }
  }, {})
  const res = await customFetch<SimpleApiResponse>('/ETF/Parameters', {
    data: mappedData,
    method: 'POST',
  })

  return res
}

export const [TMinusDataEditProvider, useTMinusDataEditState] = constate(
  ({ initData }: { initData: Record<string, ETFTMinusParameter> }) => {
    const [isEditing, setIsEditing] = useState(false)
    const [data, setData] =
      useState<Record<string, ETFTMinusParameter>>(initData)

    const startEdit = useCallback(() => {
      setData(initData)
      setIsEditing(true)
    }, [initData])

    const cancelEdit = useCallback(() => {
      setIsEditing(false)
      setData(initData)
    }, [initData])

    const save = async (): Promise<void> => {
      const agree = confirm('Confirm to save T-1 Parameters?')
      const transformedData = transformTMinusParameter(data)
      const [valid, msg] = validateTMinusParameter(transformedData)
      if (!valid) {
        alert(msg)
        return
      }
      if (agree) {
        const res = await saveTMinusParameter(transformedData)
        if (!res.isSuccess) {
          alert(res.message)
        } else {
          alert('T-1 Data updated')
          cancelEdit()
        }
      }
    }

    const onChange = useCallback(
      (coin: string, field: string, value: number | string) => {
        setData(prev => {
          return {
            ...prev,
            [coin]: {
              ...(prev[coin] || {}),
              [field]: value,
            },
          }
        })
      },
      []
    )

    return {
      data,
      initData,
      isEditing,
      startEdit,
      cancelEdit,
      save,
      onChange,
    }
  }
)
