import {
  ExchangeDetailsConfig,
  FundingRateAggregation,
  FutureSwap,
  FxRate,
  Index,
} from '../../types/business'
import { OVERVIEW } from '../dataCategory'

type DerivETHStreamData = {
  ethUsdData: FutureSwap[]
  fundingAggInfo: FundingRateAggregation[]
  fxRates: FxRate[]
  indexData: Index[]
  swapUsdData: FutureSwap[]
  swapETHUsdtData: FutureSwap[]
  futuresUsdData: FutureSwap[]
  futuresUsdtData: FutureSwap[]
  otherAltSwapsData: FutureSwap[]
  tabName: string
}

const tabs = ['All Derivs', 'All Swaps', 'Funding Rate', 'Misc']

export const DerivETHConfig: ExchangeDetailsConfig<DerivETHStreamData> = {
  title: 'DerivETH',
  dataCategory: OVERVIEW.DerivETH,
  tabs: {
    defaultValue: tabs[0],
    labels: tabs,
  },
  tables: [
    {
      type: 'FutureSwap',
      dataKey: 'futuresUsdtData',
      tableProps: {
        title: 'Futures-Traditional',
      },
      tab: tabs[0],
    },
    {
      type: 'FutureSwap',
      dataKey: 'futuresUsdData',
      tableProps: {
        title: 'Futures-Inverse',
      },
      tab: tabs[0],
    },
    {
      type: 'FutureSwap',
      dataKey: 'swapETHUsdtData',
      tableProps: {
        title: 'Swap-Traditional',
      },
      tab: tabs[1],
    },
    {
      type: 'FutureSwap',
      dataKey: 'swapUsdData',
      tableProps: {
        title: 'Swap-Inverse',
        swapTargetUsd: true,
      },
      tab: tabs[1],
    },
    {
      type: 'FundingRate',
      dataKey: 'fundingAggInfo',
      tableProps: null,
      tab: tabs[2],
    },
    {
      type: 'FxRate',
      dataKey: 'fxRates',
      tableProps: null,
      tab: tabs[3],
    },
    {
      type: 'Index',
      dataKey: 'indexData',
      tableProps: null,
      tab: tabs[3],
    },
    {
      type: 'FutureSwap',
      dataKey: 'ethUsdData',
      tableProps: {
        title: 'ETH-USD',
      },
      tab: tabs[3],
    },
  ],
}
