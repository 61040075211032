import {
  blackAlpha,
  blue,
  gray,
  green,
  orange,
  pink,
  red,
  teal,
} from './colors'

type ThemeColors = {
  primary: string
  background: string
  text: string
  subtleText: string
  success: string
  warn: string
  borderColor: string
  shadowColor: string
  // modal overlay
  overlayColor: string
  // For table
  tabelOddRowColor: string
  tabelEvenRowColor: string
  tabelHoverRowColor: string
  // For interactive element
  focusOutlineColor: string
  disabledText: string
  disabledBackground: string
  bid: string
  ask: string
  bonus: string
  subTbHeaderBgColor: string
}
export const lightThemeColors: ThemeColors = {
  primary: blue[200],
  background: 'white',
  text: gray[900],
  subtleText: orange[500],
  success: green[400],
  warn: red[400],
  borderColor: gray[500],
  shadowColor: blackAlpha[500],
  overlayColor: blackAlpha[500],
  tabelOddRowColor: 'white',
  tabelEvenRowColor: gray[200],
  tabelHoverRowColor: gray[400],
  focusOutlineColor: blue[300],
  disabledText: gray[500],
  disabledBackground: gray[200],
  bid: '#41f2ad',
  ask: '#fb8d8d',
  bonus: '#e08a00',
  subTbHeaderBgColor: teal[200],
}

export const darkThemeColors: ThemeColors = {
  primary: teal[400],
  background: teal[900],
  text: 'white',
  subtleText: pink[400],
  success: green[400],
  warn: red[400],
  borderColor: teal[500],
  shadowColor: blackAlpha[500],
  overlayColor: blackAlpha[500],
  tabelOddRowColor: teal[700],
  tabelEvenRowColor: teal[800],
  tabelHoverRowColor: 'black',
  focusOutlineColor: blackAlpha[300],
  disabledText: gray[600],
  disabledBackground: gray[500],
  bid: '#41f2ad',
  ask: '#fb8d8d',
  bonus: '#ffe433',
  subTbHeaderBgColor: 'black',
}
