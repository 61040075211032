import Cookies from 'js-cookie'

import { styled } from '../../../styles'
import { ContractType, DerivativeExData } from '../../../types/business'
import {
  convertToShortForm,
  isNullOrUndefined,
  thousandSeparate,
  toDisplayStr,
  toPercentStr,
} from '../../../utils'
import Text from '../../Text'
import Tooltip from '../../Tooltip'

type ExDataCellProps = {
  data: DerivativeExData | null
  contractType: ContractType
}

export const CellFormatWrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  '& > *': {
    border: '1px solid $borderColor',
    padding: '0 $min',
  },
})

const SubtleText = styled(Text, {
  color: '$subtleText',
  fontSize: '$tiny',
})

export const ExDataCell: React.FC<ExDataCellProps> = ({
  data,
  contractType,
}) => {
  if (isNullOrUndefined(data)) {
    return null
  }

  const excellSwitch = Cookies.get('excell_switch')
  const dataToDisplay = toDisplayStr(data, excellSwitch)

  let percentileTooltipStr = ''
  let color = {}
  const highLight = data.positive != null
  if (data.percentileData) {
    const prem2 = [1, 3, 7, 30]
      .map(day => {
        const dayData = data.percentileData.find(p => {
          return p.days === day
        })

        let dayValue = ''
        if (dayData && dayData.prem && dayData.apr) {
          const premStr = ['0.1', '0.5', '0.9']
            .map(key => {
              return toPercentStr(dayData.prem[key], 2)
            })
            .reduce((a, p) => {
              return `${a}\t\t${p}`
            })
          const aprStr = ['0.1', '0.5', '0.9']
            .map(key => {
              return toPercentStr(dayData.apr[key], 2)
            })
            .reduce((a, p) => {
              return `${a}\t\t${p}`
            })
          dayValue = `${day} Day${
            day === 1 ? '' : 's'
          }\t10% Pct\t\tMedian\t\t90% Pct\nPrem:\t${premStr}\nApr:\t${aprStr}`
        }
        return dayValue
      })
      .reduce((a, p) => {
        return `${a}\n\n${p}`
      })

    percentileTooltipStr = prem2
    if (highLight) {
      color = data.positive ? { color: '$bid' } : { color: '$ask' }
    }
  }

  if (contractType === ContractType.Future) {
    if (data.isSwap) {
      return (
        <CellFormatWrapper>
          <Text
            css={{ gridArea: '1 / 1 / span 2 / span 2', ...color }}
            title={percentileTooltipStr}
          >
            {dataToDisplay}
          </Text>
          <Text
            css={{ gridArea: '3 / 1 / span 2 / span 2' }}
            title="funding rate"
          >
            {isNullOrUndefined(data.fundingRate)
              ? 'N/A'
              : toPercentStr(data.fundingRate, 3)}
          </Text>
          <Tooltip
            message={`Volume in 24H: ${thousandSeparate(data?.volume24H || 0)}`}
          >
            <SubtleText as="span">
              {isNullOrUndefined(data?.volume24H)
                ? 'N/A'
                : convertToShortForm(data?.volume24H || 0)}
            </SubtleText>
          </Tooltip>
          <Tooltip
            message={`Open Interest: ${thousandSeparate(
              data?.openInterset || 0
            )}`}
          >
            <SubtleText as="span">
              {isNullOrUndefined(data?.openInterset)
                ? 'N/A'
                : convertToShortForm(data?.openInterset || 0)}
            </SubtleText>
          </Tooltip>
        </CellFormatWrapper>
      )
    }
    return (
      <CellFormatWrapper>
        <Text
          css={{ gridArea: '1 / 1 / span 2 / span 2', ...color }}
          title={percentileTooltipStr}
        >
          {dataToDisplay}
        </Text>
        <Tooltip
          message={`Volume in 24H: ${thousandSeparate(data?.volume24H || 0)}`}
        >
          <SubtleText as="span">
            {isNullOrUndefined(data?.volume24H)
              ? 'N/A'
              : convertToShortForm(data?.volume24H || 0)}
          </SubtleText>
        </Tooltip>
        <Tooltip
          message={`Open Interest: ${thousandSeparate(
            data?.openInterset || 0
          )}`}
        >
          <SubtleText as="span">
            {isNullOrUndefined(data?.openInterset)
              ? 'N/A'
              : convertToShortForm(data?.openInterset || 0)}
          </SubtleText>
        </Tooltip>
      </CellFormatWrapper>
    )
  }

  if (contractType === ContractType.Swap) {
    return (
      <CellFormatWrapper>
        <Text as="span" title="1 day prem.">
          {toPercentStr(data?.oneDays || 0)}
        </Text>
        <Text as="span" title="3 days prem.">
          {toPercentStr(data?.threeDays || 0)}
        </Text>
        <Text as="span" title="10 days prem.">
          {toPercentStr(data?.tenDays || 0)}
        </Text>
        <Text as="span" title="30 days prem.">
          {toPercentStr(data?.thirtyDays || 0)}
        </Text>
        <Tooltip message={`Curr APY: ${data?.curAPY}`}>
          <SubtleText as="span">
            {isNullOrUndefined(data?.curAPY)
              ? 'N/A'
              : toPercentStr(data?.curAPY || 0)}
          </SubtleText>
        </Tooltip>
        <Tooltip message={`Est APY: ${data?.estAPY}`}>
          <SubtleText as="span">
            {isNullOrUndefined(data?.estAPY)
              ? 'N/A'
              : toPercentStr(data?.estAPY || 0)}
          </SubtleText>
        </Tooltip>
        <Tooltip
          message={`Volume in 24H: ${thousandSeparate(data?.volume24H || 0)}`}
        >
          <SubtleText as="span">
            {isNullOrUndefined(data?.volume24H)
              ? 'N/A'
              : convertToShortForm(data?.volume24H || 0)}
          </SubtleText>
        </Tooltip>
        <Tooltip
          message={`Open Interest: ${thousandSeparate(
            data?.openInterset || 0
          )}`}
        >
          <SubtleText as="span">
            {isNullOrUndefined(data?.openInterset)
              ? 'N/A'
              : convertToShortForm(data?.openInterset || 0)}
          </SubtleText>
        </Tooltip>
      </CellFormatWrapper>
    )
  }

  if (contractType === ContractType.Spot) {
    return (
      <CellFormatWrapper>
        <Text as="span">{data?.bidPrice}</Text>
        <Text as="span">{data?.askPrice}</Text>
        <Tooltip
          message={`Volume in 24H: ${thousandSeparate(data?.volume24H || 0)}`}
        >
          <SubtleText as="span" css={{ gridArea: '2 / 1 / span 2 / span 2' }}>
            {isNullOrUndefined(data?.volume24H)
              ? 'N/A'
              : convertToShortForm(data?.volume24H || 0)}
          </SubtleText>
        </Tooltip>
      </CellFormatWrapper>
    )
  }
  return null
}
