import keyboardjs from 'keyboardjs'
import { FocusEventHandler, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { SEARCHABLE_PATH } from '../../constants'
import { styled } from '../../styles'
import Button from '../Button'
import { RawInput } from '../Inputs/RawInput'
import Kbd from '../Kbd'
import ScrollArea from '../ScrollArea'
import Text from '../Text'

const PATH_OPTIONS = Object.entries(SEARCHABLE_PATH).map(([key, path]) => ({
  label: key,
  value: path,
}))

const getFilteredOption = (options: SelectOption[], targetValue: string) => {
  const searchValue = targetValue.toLowerCase()
  return options.filter(
    option =>
      option.label.toLowerCase().includes(searchValue) ||
      option.value.toLowerCase().includes(searchValue)
  )
}

type SelectOption = {
  label: string
  value: string
}

export type SearchOptionItemProps = {
  label: string
  value: string
  onSelect: (args: SearchOptionItemProps['value']) => void
}

const SelectOptionListWrapper = styled('div', {
  width: '200px',
  position: 'absolute',
  transformOrigin: 'top center',
  borderRadius: '$medium',
  backgroundColor: '$background',
  boxShadow: '0 0 10px $colors$shadowColor',
  padding: '$min',
})

const SelectOptionList = styled('ul', {
  listStyle: 'none',
  padding: '$min',
  margin: 0,
})

const SearchOptionItem: React.FC<SearchOptionItemProps> = ({
  label,
  value,
  onSelect,
}) => {
  return (
    <li>
      <Button
        text
        onClick={e => {
          e.stopPropagation()
          onSelect(value)
        }}
        css={{ width: '100%', textAlign: 'left', padding: '$min' }}
      >
        {label}
      </Button>
    </li>
  )
}

const SearchKbdCombo = styled('div', {
  position: 'absolute',
  right: '$small',
  top: '50%',
  transform: 'translate(0, -50%)',
})

const NavSearchBar: React.FC = () => {
  const navigate = useNavigate()
  const inputRef = useRef<HTMLInputElement>(null)
  const [searchValue, setSearchValue] = useState<string>('')
  const [filteredOptions, setFilteredOptions] = useState<SelectOption[]>([])
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false)

  keyboardjs.bind('ctrl + k', e => {
    e?.preventDefault()
    inputRef.current?.focus()
  })

  useEffect(() => {
    const newFilteredOptions = getFilteredOption(PATH_OPTIONS, searchValue)
    setFilteredOptions(newFilteredOptions)
  }, [searchValue])

  const handleFocus: FocusEventHandler = () => {
    setIsSelectOpen(true)
  }

  const handleBlur: FocusEventHandler<HTMLDivElement> = e => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setSearchValue('')
      setIsSelectOpen(false)
    }
  }

  const handleSelect = async (newPath: string) => {
    setIsSelectOpen(false)
    setSearchValue('')
    navigate(newPath)
  }

  return (
    <div onBlur={handleBlur}>
      <SearchKbdCombo>
        <Kbd>ctrl</Kbd>
        <Kbd>K</Kbd>
      </SearchKbdCombo>
      <RawInput
        ref={inputRef}
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
        onFocus={handleFocus}
        css={{ width: '200px' }}
        placeholder="Search Page"
        onKeyDown={e => {
          if (e.key === 'Escape') {
            inputRef.current?.blur()
          }
        }}
      />

      {isSelectOpen && (
        <SelectOptionListWrapper>
          <Kbd css={{ position: 'absolute', top: '$4', right: '$4' }}>Tab</Kbd>
          <ScrollArea>
            {filteredOptions.length > 0 ? (
              <SelectOptionList role="listbox">
                {filteredOptions.map(opt => (
                  <SearchOptionItem
                    key={opt.label}
                    {...opt}
                    onSelect={handleSelect}
                  />
                ))}
              </SelectOptionList>
            ) : (
              <Text
                css={{
                  textAlign: 'center',
                  padding: '$small',
                }}
              >
                No Result
              </Text>
            )}
          </ScrollArea>
        </SelectOptionListWrapper>
      )}
    </div>
  )
}

export default NavSearchBar
