import { ComponentProps } from '@stitches/react'
import { forwardRef } from 'react'

import { styled } from '../styles'

const StyledButton = styled('button', {
  all: 'unset',
  boxSizing: 'border-box',
  position: 'relative',
  fontSize: '$medium',
  fontWeight: 'bold',
  borderRadius: '$medium',
  color: '$text',
  backgroundColor: '$primary',
  height: '40px',
  minWidth: 'fit-content',
  textAlign: 'center',
  lineHeight: 1,
  cursor: 'pointer',

  '&:hover:enabled': {
    filter: 'brightness(0.9)',
  },
  '&:active:enabled': {
    filter: 'brightness(0.8)',
  },
  '&:focus': {
    focusOutline: '$focusOutlineColor',
  },
  '&:disabled': {
    pointerEvents: 'none',
    color: '$disabledText',
    backgroundColor: '$disabledBackground',
  },

  variants: {
    text: {
      true: {
        backgroundColor: 'transparent',
        textDecoration: 'underline',
      },
    },
    size: {
      small: {
        padding: '$min $small',
      },
      medium: {
        padding: '$small $medium',
      },
    },
    unsetHeight: {
      true: {
        height: 'unset',
      },
    },
    convex: {
      true: {
        borderStyle: 'solid',
        borderColor: '$borderColor',
        borderWidth: '1px 1px 3px',
      },
    },
  },
  defaultVariants: {
    size: 'medium',
  },
})

type ButtonProps = {} & ComponentProps<typeof StyledButton>

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ type = 'button', ...rest }, ref) => (
    <StyledButton {...rest} {...{ type, ref }} />
  )
)

Button.displayName = 'Button'

export default Button
