import { useEffect } from 'react'
import { atom, useRecoilState } from 'recoil'

import { ATOM_KEY } from '../constants'
import CoinStreamDataStream from '../utils/CoinStreamDataStream'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CoinStreamDataState = atom<any>({
  key: ATOM_KEY.CoinStreamData,
  default: undefined,
})

export function useCoinStreamData<T = Record<string, unknown>>(
  category: string
) {
  const [data, setData] = useRecoilState<T | undefined>(CoinStreamDataState)

  useEffect(() => {
    CoinStreamDataStream.openDataStream(category)
    CoinStreamDataStream.setMessageHandler<T>(val => {
      if (typeof val === 'string') {
        // it is error message
      } else {
        setData(val)
      }
    })

    return () => {
      setData(undefined) // clear the data when unmount
    }
  }, [category, setData])

  return {
    data,
    isInitialized: Boolean(data),
  }
}
