import { CSS } from '@stitches/react'
import { useState } from 'react'
import { usePrevious } from 'react-use'

import { keyframes, styled, ThemeConfig } from '../../../styles'
import { isNullOrUndefined } from '../../../utils'

type NumberTrackingCellProps = {
  value: number | null
  display?: number | string
  showPlaintext?: boolean
}

const blinkFactory = (color: CSS<ThemeConfig>['color']) => {
  return keyframes({
    '0%': {
      backgroundColor: color,
    },
    '100%': {
      backgroundColor: 'transparent',
    },
  })
}

const Wrapper = styled('div', {
  textAlign: 'right',
  padding: '$tabelCellPadding',
  zIndex: 0,
  backgroundColor: 'transparent',
  variants: {
    increasing: {
      true: {
        animation: `${blinkFactory('$success')} 0.5s linear`,
      },
    },
    decreasing: {
      true: {
        animation: `${blinkFactory('$warn')} 0.5s linear`,
      },
    },
    noAnimation: {
      true: {
        animation: 'none !important',
      },
    },
  },
})

export const NumberTrackingCell: React.FC<NumberTrackingCellProps> = ({
  value,
  display,
  showPlaintext = false,
}) => {
  const prevValue = usePrevious(value)
  const [noAnimation, setNoAnimation] = useState(false)

  if (isNullOrUndefined(value)) {
    return null
  }

  if (showPlaintext) {
    if (isNullOrUndefined(display)) {
      return <>{value}</>
    }
    return <>{display}</>
  }

  const isTrend = (expected: 'increasing' | 'decreasing'): boolean => {
    if (prevValue === value || isNullOrUndefined(prevValue)) {
      return false
    }

    if (value > prevValue && expected === 'increasing') {
      return true
    }

    if (value < prevValue && expected === 'decreasing') {
      return true
    }

    return false
  }

  const onAnimationEnd = () => {
    setNoAnimation(true)
    setTimeout(() => setNoAnimation(false), 0)
  }

  return (
    <Wrapper
      increasing={isTrend('increasing')}
      decreasing={isTrend('decreasing')}
      noAnimation={noAnimation}
      onAnimationEnd={onAnimationEnd}
    >
      {isNullOrUndefined(display) ? value : display}
    </Wrapper>
  )
}
