import './utils/wakeLock'

import Cookies from 'js-cookie'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'

import App from './App'
import { CustomToastContainer } from './components/CustomToast/CustomToastContainer'
import initDevtool from './utils/devtool'
import { checkExCellSwitch } from './utils/exData'

initDevtool()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)

// init cookie

const excellSwitch = Cookies.get('excell_switch')
if (!checkExCellSwitch(excellSwitch)) {
  Cookies.set('excell_switch', 'Prem', { sameSite: 'strict' })
}

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <App />
          <CustomToastContainer />
        </BrowserRouter>
      </QueryClientProvider>
    </RecoilRoot>
  </React.StrictMode>
)
