import { Comp as Base } from '../../../components/DataTable/base'
import { RawInput } from '../../../components/Inputs/RawInput'
import { ETFTMinusParameter } from '../../../types/etf'
import { useTMinusDataEditState } from './tMinusDataEditState'

export type UpdatableNumberCellProps = {
  coin: string
  fieldKey: string
  displayValue: number | string
  type: 'number' | 'text'
}

const UpdatableNumberCell: React.FC<UpdatableNumberCellProps> = ({
  coin,
  fieldKey,
  type,
  displayValue,
}) => {
  const { data, initData, isEditing, onChange } = useTMinusDataEditState()

  const value = data?.[coin]?.[fieldKey as keyof ETFTMinusParameter] ?? ''
  const initValue =
    initData?.[coin]?.[fieldKey as keyof ETFTMinusParameter] ?? ''

  const isDirty = String(value) !== String(initValue)

  if (isEditing) {
    return (
      <Base.Td>
        <RawInput
          size="small"
          type={type}
          value={value}
          onChange={e => onChange(coin, fieldKey, e.target.value)}
          css={{
            borderColor: isDirty ? '$warn !important' : '',
          }}
        />
      </Base.Td>
    )
  }

  return <Base.Td>{displayValue}</Base.Td>
}

export default UpdatableNumberCell
