import { CoinPrice } from '../../types/business'
import dayjs from '../../utils/dayjs'
import DataTable, { DataTableColumns } from './base'
import { NumberEditCell } from './components'
import { setFilter } from './utils'

type CoinPriceFutureTableProps = {
  data: CoinPrice[]
}

const columns: DataTableColumns<CoinPrice> = [
  {
    label: 'Instrument',
    render: row => row.id,
  },
  {
    label: 'Exchange',
    render: row => row.exchange,
  },
  // {
  //   key: 'includeInBidBest',
  //   label: 'TA',
  //   render: () => <input type="checkbox" defaultChecked />,
  // },
  {
    label: 'Bid Size',
    render: row => row.bid_size.toFixed(4),
  },
  {
    label: 'Bid',
    render: row => row.bid_price.toFixed(3),
  },
  {
    label: 'Ask',
    render: row => row.ask_price.toFixed(3),
  },
  {
    label: 'Ask Size',
    render: row => row.ask_size.toFixed(4),
  },
  // {
  //   key: 'includeInAskBest',
  //   label: 'TA',
  //   render: () => <input type="checkbox" defaultChecked />,
  // },
  {
    label: 'CCY',
    render: row => row.currency,
  },
  {
    label: 'Raw Bid',
    render: row => row.bid_price_raw,
  },
  {
    label: 'Ask Bid',
    render: row => row.ask_price_raw,
  },
  {
    label: 'Timestamp',
    render: row => dayjs(row.timestamp).format('HH:mm:ss'),
  },
  {
    key: 'span1',
    label: '',
    isEmpty: true,
    render: () => '',
    width: '30px',
  },
  {
    label: 'Maker %',
    width: '170px',
    render: row => (
      <NumberEditCell
        onChange={val => setFilter('maker_commission', row, val)}
      />
    ),
  },
  {
    label: 'Taker %',
    width: '170px',
    render: row => (
      <NumberEditCell
        onChange={val => setFilter('taker_commission', row, val)}
      />
    ),
  },
  {
    label: 'Min Size %',
    width: '170px',
    render: row => (
      <NumberEditCell onChange={val => setFilter('min_size', row, val)} />
    ),
  },
]

export const CoinPriceFutureTable: React.FC<CoinPriceFutureTableProps> = ({
  data,
}) => {
  return (
    <DataTable
      rowKey="id"
      title="Future Prices"
      columns={columns}
      data={data}
    />
  )
}
