import { useMemo } from 'react'

import { AllSimpleEarnEx, SimpleEarnData } from '../../types/business'
import DataTable, { DataTableColumns } from './base'
import { SeDataCell } from './components'

type SimpleEarnExTableProps = {
  columnLabels: string[]
  data: AllSimpleEarnEx[]
  title?: string
}

type TransformedData = {
  instrumentId: AllSimpleEarnEx['alias']
  exchangeData: Record<string, SimpleEarnData | null>
}

const transformData = (data: AllSimpleEarnEx[], columnLabels: string[]) => {
  const transformedData = data.map(el => {
    const exchangeData = columnLabels.reduce((acc, exchangeLabel, index) => {
      return {
        ...acc,
        [exchangeLabel]: el.data[index],
      }
    }, {}) as SimpleEarnData
    return {
      instrumentId: el.alias || el.commonId,
      exchangeData,
    } as unknown as TransformedData
  })

  const btcEthData: TransformedData[] = []
  const restData: TransformedData[] = []

  transformedData.forEach(el => {
    const instrumentId = el.instrumentId.toLowerCase()
    if (instrumentId.includes('btc') || instrumentId.includes('eth')) {
      btcEthData.push(el)
    } else {
      restData.push(el)
    }
  })

  return transformedData
}

export const SimpleEarnExTable: React.FC<SimpleEarnExTableProps> = ({
  columnLabels,
  data,
  title,
}) => {
  const columns: DataTableColumns<TransformedData> = useMemo(() => {
    return [
      {
        label: 'Product',
        render: row => row.instrumentId,
      },
      ...(columnLabels.map(colLabel => {
        return {
          label: colLabel.replace(/Future$/, ''),
          cellStyles: {
            padding: '0',
          },
          render: row => (
            <SeDataCell data={row.exchangeData[colLabel] || null} />
          ),
        }
      }) as DataTableColumns<TransformedData>),
    ]
  }, [columnLabels])

  const transformedData = transformData(data, columnLabels)

  return (
    <DataTable
      rowKey=""
      title={title ?? ''}
      columns={columns}
      data={transformedData}
    />
  )
}
