import { ETFTMinusData } from '../../../types/etf'
import { thousandSeparate, toPercentStr } from '../../../utils'
import CoinTable, { FieldConfig } from './CoinTable'

type ETFTMinusDataTableProps = {
  data: Record<string, ETFTMinusData>
}

const fieldNameMap: Partial<Record<keyof ETFTMinusData, FieldConfig>> = {
  navPerUnitInUsd: {
    label: 'NAV per unit in USD',
    type: 'number',
    editable: true,
    format: (val: number) => val.toFixed(4),
  },
  sharesOutstanding: {
    label: '#Shares outstanding',
    type: 'number',
    editable: true,
    format: (val: number) => thousandSeparate(val),
  },
  futuresFirstMonth: {
    label: '#Futures 1st month',
    type: 'number',
    editable: true,
    format: (val: number) => thousandSeparate(val),
  },
  futuresSecondMonth: {
    label: '#Futures 2nd month',
    type: 'number',
    editable: true,
    format: (val: number) => thousandSeparate(val),
  },
  prCalc: {
    label: 'PR (Internal Calc)',
    type: 'number',
    editable: false,
    format: (val: number) => toPercentStr(val, 2),
  },
  pr: {
    label: 'PR (Official)',
    type: 'number',
    editable: true,
    format: (val: number) => `${val.toFixed(2)} %`,
  },
  prRecon: {
    label: 'PR Recon',
    type: 'number',
    editable: false,
  },
  cash: {
    label: 'Cash',
    type: 'number',
    editable: false,
    format: (val: number) => thousandSeparate(val),
  },
}

const ETFTMinusDataTable: React.FC<ETFTMinusDataTableProps> = ({ data }) => {
  return <CoinTable data={data} fieldNameMap={fieldNameMap} />
}

export default ETFTMinusDataTable
