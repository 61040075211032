import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useResetRecoilState } from 'recoil'

import { PATH } from '../constants'
import { accountInfoState } from '../stores'

export const useAuth = () => {
  const [accountInfo, setAccountInfo] = useRecoilState(accountInfoState)
  const resetAccountInfo = useResetRecoilState(accountInfoState)
  const navigate = useNavigate()

  const logout = useCallback(() => {
    resetAccountInfo()
    navigate(PATH.Login)
  }, [navigate, resetAccountInfo])

  return {
    isLogin: Boolean(accountInfo?.token),
    accountInfo,
    setAccountInfo,
    logout,
  }
}
