import {
  CoinPairInfo,
  ExchangeDetailsConfig,
  FxRate,
} from '../../types/business'
import { EXCHANGE } from '../dataCategory'

type StableStreamData = {
  fxRates: FxRate[]
  cnhCoins: CoinPairInfo[]
  othersCoins: CoinPairInfo[]
  supplyCoins: CoinPairInfo[]
  usdtCoins: CoinPairInfo[]
  usdtUsdCoins: CoinPairInfo[]
  tabName: string
}

const tabs = [
  'Misc',
  'CNH Stablecoin',
  'Stablecoin Pairs',
  'Market Capitalization',
]

export const StableConfig: ExchangeDetailsConfig<StableStreamData> = {
  title: 'Stable',
  dataCategory: EXCHANGE.Stable,
  tabs: {
    defaultValue: tabs[0],
    labels: tabs,
  },
  tables: [
    {
      type: 'FxRate',
      dataKey: 'fxRates',
      tableProps: null,
      tab: tabs[0],
    },
    {
      type: 'CoinPairInfo',
      dataKey: 'cnhCoins',
      tableProps: {
        title: 'CNY-CNH',
      },
      tab: tabs[1],
    },
    {
      type: 'CoinPairInfo',
      dataKey: 'usdtUsdCoins',
      tableProps: {
        title: 'USDT-USD',
      },
      tab: tabs[2],
    },
    {
      type: 'CoinPairInfo',
      dataKey: 'usdtCoins',
      tableProps: {
        title: 'STABLE-USDT',
      },
      tab: tabs[2],
    },
    {
      type: 'CoinPairInfo',
      dataKey: 'othersCoins',
      tableProps: {
        title: 'OTHERS',
      },
      tab: tabs[2],
    },
    {
      type: 'TokenSupplyInfo',
      dataKey: 'supplyCoins',
      tableProps: null,
      tab: tabs[3],
    },
  ],
}
