import {
  ExchangeDetailsConfig,
  FundingRateAggregation,
  FutureDeliveryInfo,
  FutureSwap,
  FxRate,
  Index,
} from '../../types/business'
import { EXCHANGE } from '../dataCategory'

type BinanceStreamData = {
  fundingAggInfo: FundingRateAggregation[]
  futureInfo: FutureDeliveryInfo[]
  fxRates: FxRate[]
  indexData: Index[]
  takerTakerData: FutureSwap[]
  takerTakerUSDTMarginData: FutureSwap[]
  takerTakerBUSDMarginData: FutureSwap[]
  tabName: string
}

const tabs = [
  'All Derivs-USDT',
  'All Derivs-BUSD',
  'All Derivs-Coin',
  'Funding Rate',
  'Misc',
]

export const BinanceConfig: ExchangeDetailsConfig<BinanceStreamData> = {
  title: 'Binance',
  dataCategory: EXCHANGE.Binance,
  tabs: {
    defaultValue: tabs[0],
    labels: tabs,
  },
  tables: [
    {
      type: 'FutureSwap',
      dataKey: 'takerTakerUSDTMarginData',
      tableProps: {
        title: 'Binance vs All Derivs-USDT',
      },
      tab: tabs[0],
    },
    {
      type: 'FutureSwap',
      dataKey: 'takerTakerBUSDMarginData',
      tableProps: {
        title: 'Binance vs All Derivs-BUSD',
      },
      tab: tabs[1],
    },
    {
      type: 'FutureSwap',
      dataKey: 'takerTakerData',
      tableProps: {
        title: 'Binance vs All Derivs-Coin',
      },
      tab: tabs[2],
    },
    {
      type: 'FundingRate',
      dataKey: 'fundingAggInfo',
      tableProps: {
        supportedTypes: ['USDT', 'BUSD', 'COIN'],
      },
      tab: tabs[3],
    },
    {
      type: 'FxRate',
      dataKey: 'fxRates',
      tableProps: null,
      tab: tabs[4],
    },
    {
      type: 'Index',
      dataKey: 'indexData',
      tableProps: null,
      tab: tabs[4],
    },
    {
      type: 'FutureDelivery',
      dataKey: 'futureInfo',
      tableProps: null,
      tab: tabs[4],
    },
  ],
}
