/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */

// This is used to prevent the application from falling asleep
let lockResolver
const anyNavigator = navigator as any

if (anyNavigator && anyNavigator.locks && anyNavigator.locks.request) {
  const promise = new Promise(res => {
    lockResolver = res
  })

  anyNavigator.locks.request('unique_lock_name', { mode: 'shared' }, () => {
    return promise
  })
}

export {}
