import { DefiApyInfo } from '../../types/business'
import DataTable, { DataTableColumns } from './base'

type DefiApyTableProps = {
  data: DefiApyInfo[]
}

const columns: DataTableColumns<DefiApyInfo> = [
  {
    label: 'Symbol',
    render: row => row.symbol,
  },
  {
    label: 'APY',
    render: row => row.apy,
  },
  {
    label: 'Timestamp',
    render: row => row.timestamp,
  },
]

export const DefiApyTable: React.FC<DefiApyTableProps> = ({ data }) => {
  return <DataTable rowKey="symbol" title="" columns={columns} data={data} />
}
