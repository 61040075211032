import { CSS } from '@stitches/react'
import { useCallback, useState } from 'react'

import { styled, ThemeConfig } from '../../styles'
import Heading from '../Heading'
import Text from '../Text'

type RenderContext<T> = {
  col: DataTableColumn<T>
  index: number
}

type DataTableColumn<T> = {
  key?: string // provide key if there are duplicated labels
  label: string
  caption?: string // descriptive text for the header label
  // headerRender?: (label?: string) => React.ReactDOM | JSX.Element
  width?: `${string}px`
  // align?: 'left' | 'center' | 'right'
  isEmpty?: boolean // act as empty column
  cellStyles?: CSS<ThemeConfig>
  hidden?: boolean
  render: (
    row: T,
    ctx: RenderContext<T>
  ) => React.ReactDOM | JSX.Element | number | string | undefined | null
}

export type DataTableColumns<T = Record<string, unknown>> = DataTableColumn<T>[]

export type DataTableProps<T = Record<string, unknown>> = {
  rowKey: string | ((row: T) => string)
  title?: string
  noStickyHeader?: boolean
  tableHeight?: CSS<ThemeConfig>['height']
  columns: DataTableColumns<T>
  data: T[]
}

const cellStyles: CSS<ThemeConfig> = {
  minWidth: '80px',
  textAlign: 'center',
  padding: '$tabelCellPadding',
  whiteSpace: 'nowrap',
  fontSize: '$small',
}

const Tr = styled('tr', {
  'th:first-child, td:first-child': {
    position: 'sticky',
    left: 0,
    zIndex: 1,
    backgroundColor: 'inherit',
    boxShadow: 'inset -2px 0px 0px 0px $colors$borderColor',
  },
})

const StyledTh = styled('th', {
  ...cellStyles,
  cursor: 'pointer',
  variants: {
    isEmpty: {
      true: {
        color: 'transparent',
      },
    },
    isSelected: {
      true: {
        backgroundColor: '$tabelHoverRowColor !important',
      },
    },
  },
})

const StyledTd = styled('td', {
  ...cellStyles,
  variants: {
    isEmpty: {
      true: {
        color: 'transparent',
      },
    },
    isSelected: {
      true: {
        backgroundColor: '$tabelHoverRowColor !important',
      },
    },
  },
})

const Thead = styled('thead', {
  position: 'sticky',
  top: 0,
  zIndex: 2,
  boxShadow: 'inset 0px 2px 0px 0px $colors$borderColor',
  '& > tr': {
    backgroundColor: '$primary',
  },
  variants: {
    noSticky: {
      true: {
        position: 'relative',
        top: 'unset',
      },
    },
  },
})

const Tbody = styled('tbody', {
  '& > tr': {
    backgroundColor: '$tabelOddRowColor',
  },
  '& > tr:nth-child(2n)': {
    backgroundColor: '$tabelEvenRowColor',
  },
  '& > tr:hover > td': {
    backgroundColor: '$tabelHoverRowColor',
  },
})

const NoDataPlaceholder = styled('div', {
  padding: '$tiny',
  flexRowCenter: 'center',
})

const Table = styled('table', {
  position: 'relative',
  tableLayout: 'fixed',
  borderCollapse: 'collapse',
  [`&, & ${StyledTh}, & ${StyledTd}, & + ${NoDataPlaceholder}`]: {
    border: '2px solid $colors$borderColor',
  },
})

const Wrapper = styled('div', {})

const TableContainer = styled('div', {
  width: 'min-content',
  maxWidth: '100%',
  maxHeight: '75vh',
  overflow: 'auto',
})

const generateRowKey = (
  row: any,
  rowKey: DataTableProps<any>['rowKey'],
  index: number
) => {
  if (row === null || row === undefined) {
    return index
  }

  if (typeof rowKey === 'function') {
    return rowKey(row)
  }
  return `${row[rowKey]}_${index}`
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getColKey = (col: DataTableColumn<any>) => {
  return col.key || col.label
}

const Th: React.FC<{
  thKey: string
  col: DataTableColumn<any>
  isSelected: boolean
  onSelect: (thKey: string) => void
}> = ({ thKey, col, isSelected, onSelect }) => {
  if (col.hidden) {
    return null
  }

  return (
    <StyledTh
      isEmpty={col.isEmpty}
      {...(col.width ? { css: { minWidth: col.width } } : {})}
      isSelected={isSelected}
      onClick={() => onSelect(thKey)}
    >
      <Text css={{ fontSize: 'inherit' }}>{col.label}</Text>
      {col.caption && (
        <Text
          css={{
            marginTop: '$tabelCellPadding',
            fontSize: '$tiny',
          }}
        >
          {col.caption}
        </Text>
      )}
    </StyledTh>
  )
}

const Td: React.FC<{
  row: any
  index: number
  col: DataTableColumn<any>
  isSelected: boolean
}> = ({ row, index, col, isSelected }) => {
  return (
    <StyledTd
      css={{
        ...(col.cellStyles ?? {}),
        ...(col.width ? { minWidth: col.width } : {}),
      }}
      isSelected={isSelected}
    >
      <>
        {row ? (
          col.render(row, { col, index })
        ) : (
          <span style={{ display: 'inline-block' }} /> // render empty cell if row === null
        )}
      </>
    </StyledTd>
  )
}

function DataTable<T = Record<string, unknown>>({
  rowKey,
  title,
  columns,
  data,
  noStickyHeader = false,
  tableHeight,
}: DataTableProps<T>): JSX.Element {
  const [selectColMap, setSelectColMap] = useState(
    {} as Record<string, boolean>
  )

  const onThClick = useCallback((key: string) => {
    setSelectColMap(acc => ({
      ...acc,
      [key]: !acc[key],
    }))
  }, [])

  return (
    <Wrapper>
      {title && <Heading>{title}</Heading>}
      <TableContainer css={{ ...(tableHeight ? { height: tableHeight } : {}) }}>
        <Table>
          <Thead noSticky={noStickyHeader}>
            <Tr>
              {columns.map(col => {
                if (col.hidden) {
                  return null
                }
                const thKey = getColKey(col)
                return (
                  <Th
                    key={thKey}
                    thKey={thKey}
                    col={col}
                    isSelected={selectColMap[thKey]}
                    onSelect={onThClick}
                  />
                )
              })}
            </Tr>
          </Thead>
          <Tbody>
            {data?.map((row, index) => {
              const tableRowKey = generateRowKey(row, rowKey, index)
              // for normal row
              return (
                <Tr key={tableRowKey}>
                  {columns.map(col => {
                    if (col.hidden) {
                      return null
                    }
                    const thKey = getColKey(col)
                    return (
                      <Td
                        key={thKey}
                        row={row}
                        index={index}
                        col={col}
                        isSelected={selectColMap[thKey]}
                      />
                    )
                  })}
                </Tr>
              )
            })}
          </Tbody>
        </Table>
        {/* show no data if data is undefined or empty */}
        {(!data || !data.length) && (
          <NoDataPlaceholder>No Data</NoDataPlaceholder>
        )}
      </TableContainer>
    </Wrapper>
  )
}

export const Comp = {
  Table,
  Thead,
  Tbody,
  Th: StyledTh,
  Tr,
  Td: StyledTd,
}

export default DataTable
