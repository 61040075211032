import {
  ExchangeDetailsConfig,
  FundingRateAggregation,
  FutureDeliveryInfo,
  FutureSwap,
  FxRate,
  LendingRateInfo,
} from '../../types/business'
import { EXCHANGE } from '../dataCategory'

type FtxStreamData = {
  fundingAggInfo: FundingRateAggregation[]
  futData: FutureSwap[]
  futureInfo: FutureDeliveryInfo[]
  fxRates: FxRate[]
  lendingRateInfo: LendingRateInfo[]
  tabName: string
}

const tabs = ['All Derivs', 'Funding Rate', 'Misc']

export const FtxConfig: ExchangeDetailsConfig<FtxStreamData> = {
  title: 'FTX',
  dataCategory: EXCHANGE.Ftx,
  tabs: {
    defaultValue: tabs[0],
    labels: tabs,
  },
  tables: [
    {
      type: 'FutureSwap',
      dataKey: 'futData',
      tableProps: {
        title: 'Ftx vs All Derivs (Taker / Taker Premium)',
        dailySettlementCount: 24,
      },
      tab: tabs[0],
    },
    {
      type: 'FundingRate',
      dataKey: 'fundingAggInfo',
      tableProps: null,
      tab: tabs[1],
    },
    {
      type: 'FxRate',
      dataKey: 'fxRates',
      tableProps: null,
      tab: tabs[2],
    },
    {
      type: 'FutureDelivery',
      dataKey: 'futureInfo',
      tableProps: null,
      tab: tabs[2],
    },
    {
      type: 'LendingRate',
      dataKey: 'lendingRateInfo',
      tableProps: null,
      tab: tabs[2],
    },
  ],
}
