import { styled } from '../../../styles'
import { SimpleEarnData } from '../../../types/business'
import { isNullOrUndefined, toPercentStr } from '../../../utils'
import Text from '../../Text'

type SeDataCellProps = {
  data: SimpleEarnData | null
}

export const SeCellFormatWrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  '& > *': {
    // border: '1px solid $borderColor',
    padding: '0 $min',
  },
})

export const SeDataCell: React.FC<SeDataCellProps> = ({ data }) => {
  if (isNullOrUndefined(data)) {
    return (
      <SeCellFormatWrapper>
        <Text css={{ width: 180 }} as="span" />
      </SeCellFormatWrapper>
    )
  }

  let dataToDisplay = toPercentStr(data.apy)
  if (data.hasBonus) {
    dataToDisplay = `${dataToDisplay} + ${toPercentStr(data.bonus)}`
  }

  return (
    <SeCellFormatWrapper>
      <Text
        css={{ width: 180, ...(data.hasBonus ? { color: '$bonus' } : {}) }}
        as="span"
      >
        {dataToDisplay}
      </Text>
    </SeCellFormatWrapper>
  )
}
