import { useState } from 'react'

import Box from './Box'
import { CellFormatWrapper } from './DataTable/components'
import { RawInput } from './Inputs/RawInput'

const RadioGroup = ({
  initialValue,
  stateList,
  stateChanged,
  css,
}: any): JSX.Element => {
  const [curState, setState] = useState(initialValue)

  const handleToggle = (event: any) => {
    // console.log(event)
    setState(event.target.value)
    stateChanged(event.target.value)
  }

  return (
    <Box css={css}>
      <CellFormatWrapper css={{ textAlign: 'left' }}>
        {stateList
          .map((state: string) => [state, state])
          .flat()
          .map((state: string, index: number) => {
            return index % 2 === 0 ? (
              <RawInput
                key={state}
                type="radio"
                value={state}
                checked={curState === state}
                onChange={handleToggle}
              />
            ) : (
              state
            )
          })}

        {/* <RawInput type={'radio'} value={'Prem'} />Prem
                <RawInput type={'radio'} value={'APY'} /> APY
                <RawInput type={'radio'} value={'Absolute'} /> Absolute */}
      </CellFormatWrapper>
    </Box>
  )
}

export default RadioGroup

// const Toggle = ({ label, toggled, onClick }) => {
//     const [isToggled, toggle] = useState(toggled)

//     const callback = () => {
//         toggle(!isToggled)
//         onClick(!isToggled)
//     }
//     label = {
//         position: "relative",
//         display: "inline-block",
//         width: "60px",
//         height: "300px"
//     }

//     return (
//         <label>
//             <input type="checkbox" defaultChecked={isToggled} onClick={callback} />
//             <span />
//             <strong>{label}</strong>
//         </label>
//     )
// }

// export default Toggle
