import { useMemo } from 'react'
import { useRecoilState } from 'recoil'

import { percentileDays } from '../../stores/percentileData'
import { Percentile, PercentileData } from '../../types/business'
import DataTable, { DataTableColumns } from './base'
import { PercentileDataCell } from './components/PremcentileDataCell'

type FuturePremTableProps = {
  columnLabels: string[]
  data: Percentile[]
  title?: string
}

type TransformedData = {
  instrumentId: Percentile['alias']
  exchangeData: Record<string, PercentileData | null>
}

const transformData = (
  data: Percentile[],
  columnLabels: string[],
  day: number
) => {
  const transformedData = data.map(item => {
    const exchangeData = columnLabels.reduce((acc, exchangeLabel) => {
      const res = item.datas
        .filter(sData => sData.days === day)
        .find(sData => sData.exchange === exchangeLabel)
      return {
        ...acc,
        [exchangeLabel]: res,
      }
    }, {}) as PercentileData
    return {
      instrumentId: item.alias || item.commonId,
      exchangeData,
    } as unknown as TransformedData
  })
  const defaultExchangeData: Record<string, PercentileData | null> = {}
  columnLabels.forEach(label => {
    defaultExchangeData[label] = {
      '0.1': '10% Pct',
      '0.5': 'Median',
      '0.9': '90% Pct',
      days: 3,
      exchange: label,
      symbol: '',
    } as unknown as PercentileData
  })
  const defaultData: TransformedData = {
    instrumentId: '',
    exchangeData: defaultExchangeData,
  } as unknown as TransformedData
  return [defaultData, ...transformedData.sort()]
}

export const FuturePremTable: React.FC<FuturePremTableProps> = ({
  columnLabels,
  data,
  title,
}) => {
  const [dataDays] = useRecoilState(percentileDays)
  const columns: DataTableColumns<TransformedData> = useMemo(() => {
    return [
      {
        label: '',
        render: (row: TransformedData) => row.instrumentId,
      },
      ...(columnLabels.map(colLabel => {
        return {
          label: colLabel.replace(/Future$/, ''),
          cellStyles: {
            padding: '0',
          },
          render: row => (
            <PercentileDataCell data={row.exchangeData[colLabel]} />
          ),
        }
      }) as DataTableColumns<TransformedData>),
    ]
  }, [columnLabels])
  const transformedData = transformData(data, columnLabels, dataDays)
  return (
    <DataTable
      rowKey=""
      title={`${title}(${dataDays}d)` ?? ''}
      columns={columns}
      data={transformedData}
    />
  )
}
