import { MdDarkMode, MdExitToApp, MdLightMode } from 'react-icons/md'
import { useRecoilState } from 'recoil'

import { useAuth } from '../../hooks'
import { darkModeState } from '../../stores'
import { styled } from '../../styles'
import Button from '../Button'
import Heading from '../Heading'
import IconButton from '../IconButton'
import Spacer from '../Spacer'
import ChangePasswordModal from './ChangePasswordModal'
import DataStreamStatus from './DataStreamStatus'
import NavMenu from './NavMenu'
import NavSearchBar from './NavSearchBar'

const Wrapper = styled('header', {
  width: '100%',
  padding: '$min $medium',
  flexRowCenter: 'flex-start',
  gap: '$4',
  borderBottom: '2px solid $borderColor',
  backgroundColor: '$background',
  position: 'sticky',
  top: 0,
  left: 0,
  zIndex: '$navBar',
})

const Header: React.FC = () => {
  const [isDarkMode, setDarkMode] = useRecoilState(darkModeState)
  const { accountInfo, logout } = useAuth()

  return (
    <Wrapper>
      <Heading size="small">CoinStream</Heading>
      <NavMenu />
      <Spacer />
      <NavSearchBar />
      <DataStreamStatus />
      <IconButton
        icon={isDarkMode ? <MdLightMode /> : <MdDarkMode />}
        onClick={() => setDarkMode(val => !val)}
        title="Change color theme"
      />
      <ChangePasswordModal
        trigger={<Button text>{accountInfo?.username}</Button>}
      />
      <IconButton icon={<MdExitToApp />} onClick={logout} title="Logout" />
    </Wrapper>
  )
}

export default Header
