import {
  ExchangeDetailsConfig,
  FundingRateAggregation,
  FutureDeliveryInfo,
  FutureSwap,
  FxRate,
  Index,
} from '../../types/business'
import { EXCHANGE } from '../dataCategory'

type OKExStreamData = {
  fundingRateInfo: FundingRateAggregation[]
  futData: FutureSwap[]
  futUsdtData: FutureSwap[]
  futureInfo: FutureDeliveryInfo[]
  fxRates: FxRate[]
  indexData: Index[]
  tabName: string
}

const tabs = ['All Derivs-USDT', 'All Derivs-Coin', 'Funding Rate', 'Misc']

export const OKExConfig: ExchangeDetailsConfig<OKExStreamData> = {
  title: 'OKEx',
  dataCategory: EXCHANGE.OKEx,
  tabs: {
    defaultValue: tabs[0],
    labels: tabs,
  },
  tables: [
    {
      type: 'FutureSwap',
      dataKey: 'futUsdtData',
      tableProps: {
        title: 'OKEx vs All Derivs USDT (Taker / Taker Premium)',
      },
      tab: tabs[0],
    },
    {
      type: 'FutureSwap',
      dataKey: 'futData',
      tableProps: {
        title: 'OKEx vs All Derivs (Taker / Taker Premium)',
      },
      tab: tabs[1],
    },
    {
      type: 'FundingRate',
      dataKey: 'fundingRateInfo',
      tableProps: null,
      tab: tabs[2],
    },
    {
      type: 'FxRate',
      dataKey: 'fxRates',
      tableProps: null,
      tab: tabs[3],
    },
    {
      type: 'Index',
      dataKey: 'indexData',
      tableProps: null,
      tab: tabs[3],
    },
    {
      type: 'FutureDelivery',
      dataKey: 'futureInfo',
      tableProps: null,
      tab: tabs[3],
    },
  ],
}
