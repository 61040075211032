import { lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

import AppLayout from './components/AppLayout'
import ProtectedRoute from './components/ProtectedRoute'
import Spinner from './components/Spinner'
import { PATH } from './constants'
import { useDarkMode } from './hooks'
import Dashboard from './pages/Dashboard'
import ETF from './pages/ETF'
import SchemaDetails from './pages/SchemaDetails'
import { globalStyles } from './styles/globalStyle'

const Admin = lazy(() => import('./pages/Admin'))
const Login = lazy(() => import('./pages/Login'))
const PageNotFound = lazy(() => import('./pages/PageNotFound'))

const App = (): JSX.Element => {
  globalStyles()
  useDarkMode()

  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route path={PATH.Login} element={<Login />} />
        <Route path="/" element={<ProtectedRoute />}>
          <Route path="/" element={<AppLayout />}>
            <Route index element={<Dashboard />} />
            <Route path={PATH.Admin} element={<Admin />} />
            <Route path={PATH.ETF} element={<ETF />} />
            <Route
              path="/overview/:overviewName"
              element={<SchemaDetails configKey="overviewName" />}
            />
            <Route
              path="/exchange/:exchangeName"
              element={<SchemaDetails configKey="exchangeName" />}
            />
            <Route
              path="/coin/:coinName"
              element={<SchemaDetails configKey="coinName" />}
            />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  )
}

export default App
