import {
  AllSimpleEarnEx,
  ContractType,
  ExchangeDetailsConfig,
  SimpleEarnData,
} from '../../types/business'
import { OVERVIEW } from '../dataCategory'

type SimpleEarnStreamData = {
  coins: string[] // available exchanges in the futures table
  periods: string[] // available exchanges in the futures table
  data: Record<string, Record<string, SimpleEarnData[]>> // data for the current quarter
  tabName: string
}

type AllSimpleEarnData = {
  allData: SimpleEarnStreamData
}

const tabs = ['Current']

export const SimpleEarnConfig: ExchangeDetailsConfig<AllSimpleEarnData> = {
  title: 'SimpleEarn',
  dataCategory: OVERVIEW.SimpleEarn,
  tabs: {
    defaultValue: tabs[0],
    labels: tabs,
    gridProperties: {
      Overview: {
        gridTemplateColumns: '3fr 4fr',
        gridTemplateAreas: `
          'all header'
          'btc stable'
          'eth stable'
        `,
      },
    },
  },
  tables: [
    {
      type: 'SimpleEarnEx',
      dataKey: 'allData',
      transformData: transformFnFactory('binance'),
      tableProps: {
        title: 'Binance',
        contractType: ContractType.Future,
      },
      tablePropsExtractor: data => ({
        columnLabels: data.allData.periods,
      }),
      tab: tabs[0],
      gridArea: 'btc',
    },
    {
      type: 'SimpleEarnEx',
      dataKey: 'allData',
      transformData: transformFnFactory('okEx'),
      tableProps: {
        title: 'OKEx',
        contractType: ContractType.Future,
      },
      tablePropsExtractor: data => ({
        columnLabels: data.allData.periods,
      }),
      tab: tabs[0],
      gridArea: 'eth',
    },
  ],
}

function transformFnFactory(
  exchange: string
): (data: SimpleEarnStreamData) => AllSimpleEarnEx[] {
  return (data: SimpleEarnStreamData): AllSimpleEarnEx[] => {
    // console.log(data)
    const result = data.coins.map(coinName => {
      const dataInstKey = coinName.toLowerCase()

      const rawDatas = data?.data?.[exchange]?.[dataInstKey] || []
      const finalDatas = data.periods.map(period => {
        return rawDatas.find(x => x.period === period) || null
      })
      return {
        alias: coinName,
        commonId: coinName,
        data: finalDatas,
      } as AllSimpleEarnEx
    })
    // console.log(result)
    return result
  }
}
