import 'react-toastify/dist/ReactToastify.css'

import { ToastContainer } from 'react-toastify'

export const CustomToastContainer = () => (
  <ToastContainer
    position="top-right"
    autoClose={3000}
    toastStyle={{ top: '48px' }}
    hideProgressBar={false}
    newestOnTop
    closeOnClick
    pauseOnFocusLoss
    // draggable
    // pauseOnHover
  />
)
