import { useCallback, useMemo } from 'react'

import { FundingRateAggregation } from '../../types/business'
import Box from '../Box'
import DataTable, { DataTableColumns } from './base'

type FundingCoinType = 'USDT' | 'BUSD' | 'COIN'

type FundingRateTableProps = {
  data?: FundingRateAggregation[]
  supportedTypes?: FundingCoinType[]
}

type TransformedFundingRateAggregation = Record<
  keyof Omit<FundingRateAggregation, 'symbol'>,
  (string | undefined)[]
> & { symbol: string }

const fundingRateLabelDict: Record<
  keyof Omit<FundingRateAggregation, 'symbol'>,
  string
> = {
  oneDays: '1 Day',
  threeDays: '3 Days',
  tenDays: '10 Days',
  thirtyDays: '30 Days',
}

const checkInst = (symbol: string) => {
  const isUsdt = symbol.endsWith('(T)')
  const isBusd = symbol.endsWith('(B)')
  const originalSymbol = symbol.replace(/SWAP.*$/, 'SWAP')
  // eslint-disable-next-line no-nested-ternary
  const type = isUsdt ? 'USDT' : isBusd ? 'BUSD' : 'COIN'
  return [type as FundingCoinType, originalSymbol] as const
}

const arrayToTd = (data: (string | undefined)[]): JSX.Element => {
  return (
    <Box
      css={{
        lineHeight: '1.5',
        flexRowCenter: 'center',
        '& > *': {
          flex: 1,
          border: 'solid 1px $borderColor',
        },
      }}
    >
      {data.map((el, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index}>{el || 'N/A'}</div>
      ))}
    </Box>
  )
}

const formatValue = (val: number | null) => {
  if (val === null) {
    return 'N/A'
  }
  return `${(val * 100).toFixed(2)}%`
}

export const FundingRateTable: React.FC<FundingRateTableProps> = ({
  data: rawData = [],
  supportedTypes = ['USDT', 'COIN'],
}) => {
  const columns: DataTableColumns<TransformedFundingRateAggregation> =
    useMemo(() => {
      return [
        {
          label: 'Symbol',
          render: row => row.symbol,
        },
        ...(Object.entries(fundingRateLabelDict).map(([key, label]) => ({
          label,
          caption: supportedTypes.join(' / '),
          render: row =>
            arrayToTd(row[key as keyof typeof fundingRateLabelDict]),
          width: '200px',
          cellStyles: {
            padding: '0',
          },
        })) as DataTableColumns<TransformedFundingRateAggregation>),
      ]
    }, [supportedTypes])

  const transformData = useCallback(
    (data: FundingRateAggregation[]): TransformedFundingRateAggregation[] => {
      const dict: Record<
        string,
        Partial<
          Record<
            FundingCoinType,
            Record<keyof Omit<FundingRateAggregation, 'symbol'>, string> | null
          >
        >
      > = {}
      /**
       * Transform to dictionary of:
       * {
       *   'BTC SWAP': {
       *     USDT: {
       *       oneDays: ...
       *       threeDays: ...
       *       tenDays: ...
       *       thirtyDays: ...
       *     },
       *     COIN: {
       *       oneDays: ...
       *       threeDays: ...
       *       tenDays: ...
       *       thirtyDays: ...
       *     },
       *   },
       *   'ETH SWAP': ...
       * }
       */
      data.forEach(el => {
        const [type, originalSymbol] = checkInst(el.symbol)
        if (!dict[originalSymbol]) {
          dict[originalSymbol] = {}
        }

        dict[originalSymbol][type] = {
          oneDays: formatValue(el.oneDays),
          threeDays: formatValue(el.threeDays),
          tenDays: formatValue(el.tenDays),
          thirtyDays: formatValue(el.thirtyDays),
        }
      })

      const transformedData = Object.entries(dict).map(([key, value]) => {
        return {
          symbol: key,
          oneDays: supportedTypes.map(type => value[type]?.oneDays),
          threeDays: supportedTypes.map(type => value[type]?.threeDays),
          tenDays: supportedTypes.map(type => value[type]?.tenDays),
          thirtyDays: supportedTypes.map(type => value[type]?.thirtyDays),
        }
      })

      return transformedData
    },
    [supportedTypes]
  )

  return (
    <DataTable
      rowKey="symbol"
      title="Funding Rate"
      columns={columns}
      data={transformData(rawData)}
    />
  )
}
