import { ComponentProps, forwardRef } from 'react'

import { InputMeta } from '../../types'
import Label from './Label'
import { RawInput } from './RawInput'

export type TextInputProps = {
  error?: string
} & ComponentProps<typeof RawInput> &
  Partial<InputMeta>

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (props, ref) => {
    return (
      <div>
        {props.label && <Label>{props.label}</Label>}
        <RawInput {...props} ref={ref} />
      </div>
    )
  }
)
