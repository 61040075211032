import { pick } from 'ramda'
import { useMemo } from 'react'

import { EXCHANGE } from '../../constants'
import { FutureSwap } from '../../types/business'
import { convertToShortForm, formatTs, isNullOrUndefined } from '../../utils'
import DataTable, { DataTableColumns } from './base'
import { NumberTrackingCell, RateInfoCell } from './components'

type FutureSwapTableProps = {
  dataCategory: string // TEMP: for insert blank line, specific for Kraken, HuobiPro, and Deribit
  title: string
  data?: FutureSwap[]
  dailySettlementCount?: number
  isUnderlying?: boolean // should coin price only, not instrument
  swapTargetUsd?: boolean // if the instructment is Swap and target USD
}

const SPECIAL_HANDLE_EXCHANGES = [
  EXCHANGE.Deribit,
  EXCHANGE.Kraken,
  EXCHANGE.Huobi,
]

const transformData = (
  data: FutureSwap[],
  dataCategory: string
): FutureSwap[] => {
  if (!SPECIAL_HANDLE_EXCHANGES.includes(dataCategory)) {
    return data
  }

  const newData = [] as FutureSwap[]

  data.forEach((item, index) => {
    if (
      index > 0 &&
      (item.exchange === 'BINANCE' || item.exchange === 'HuobiPro')
    ) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      newData.push(null as any)
    }

    newData.push(item)
  })

  return newData as FutureSwap[]
}

export const FutureSwapTable: React.FC<FutureSwapTableProps> = ({
  dataCategory,
  title,
  data = [],
  isUnderlying = false,
  swapTargetUsd = false,
  dailySettlementCount,
}) => {
  const columns: DataTableColumns<FutureSwap> = useMemo(
    () => [
      {
        label: isUnderlying ? 'Underlying' : 'Instrument',
        render: row => row.instrumentId,
      },
      { label: 'Exchange', render: row => row.exchange },
      {
        label: 'Bid Size',
        cellStyles: { padding: 0 },
        render: row => <NumberTrackingCell value={row.bidSize} showPlaintext />,
        width: '100px',
      },
      {
        label: swapTargetUsd ? 'Bid-USD' : 'Bid',
        cellStyles: { padding: 0 },
        render: row => <NumberTrackingCell value={row.bid} showPlaintext />,
        width: '100px',
      },
      {
        label: swapTargetUsd ? 'Ask-USD' : 'Ask',
        cellStyles: { padding: 0 },
        render: row => <NumberTrackingCell value={row.ask} showPlaintext />,
        width: '100px',
      },
      {
        label: 'Ask Size',
        cellStyles: { padding: 0 },
        render: row => <NumberTrackingCell value={row.askSize} showPlaintext />,
        width: '100px',
      },
      {
        label: 'Target',
        render: row => row.target,
      },
      {
        label: 'Prem Bid',
        cellStyles: { padding: 0 },
        render: row => (
          <NumberTrackingCell
            value={
              isNullOrUndefined(row.futPremiumBid)
                ? null
                : Number.parseFloat(row.futPremiumBid.split(' ')[0])
            }
            display={row.futPremiumBid || ''}
            showPlaintext
          />
        ),
      },
      {
        label: 'Prem Ask',
        cellStyles: { padding: 0 },
        render: row => (
          <NumberTrackingCell
            value={
              isNullOrUndefined(row.futPremiumAsk)
                ? null
                : Number.parseFloat(row.futPremiumAsk.split(' ')[0])
            }
            display={row.futPremiumAsk || ''}
            showPlaintext
          />
        ),
      },
      { label: '', isEmpty: true, render: () => '', width: '30px' },
      {
        label: 'Raw Bid',
        render: ({ rawBid }) =>
          rawBid < 0 ? rawBid.toPrecision(5) : rawBid.toFixed(5),
      },
      {
        label: 'Raw Ask',
        render: ({ rawAsk }) =>
          rawAsk < 0 ? rawAsk.toPrecision(5) : rawAsk.toFixed(5),
      },
      {
        label: '24H Volume',
        render: row =>
          convertToShortForm(row.h24Volume?.replaceAll(',', '') || ''),
      },
      {
        label: 'Open Interest',
        render: row =>
          convertToShortForm(row.openInterest?.replaceAll(',', '') || ''),
      },
      { label: 'Insurance', render: row => row.insuranceBalance },
      {
        label: 'Timestamp',
        render: row => formatTs(row.timestamp || ''),
      },
      {
        label: 'Curr. Rate',
        render: row => (
          <RateInfoCell
            currentValue={row.currRate || ''}
            dailySettlementCount={dailySettlementCount}
            {...pick(['id', 'common_id', 'exchange_raw'], row)}
          />
        ),
      },
      {
        label: 'Est. Rate',
        render: row => (
          <RateInfoCell
            currentValue={row.estRate || ''}
            dailySettlementCount={dailySettlementCount}
            {...pick(['id', 'common_id', 'exchange_raw'], row)}
          />
        ),
      },
      { label: 'APY', render: row => row.apy },
    ],
    [dailySettlementCount, isUnderlying, swapTargetUsd]
  )

  return (
    <DataTable
      rowKey="instrumentId"
      title={title}
      columns={columns}
      data={transformData(data, dataCategory)}
    />
  )
}
