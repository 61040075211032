import { get } from 'lodash-es'
import { Fragment, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import {
  AllStableExTable,
  CoinPairInfoTable,
  CoinPriceFutureTable,
  CoinPriceTable,
  DefiApyTable,
  DerivativeExTable,
  DerivativeExTableApy,
  DerivativeExTableV2,
  FundingRateTable,
  FutureDeliveryTable,
  FutureSwapTable,
  FxRateTable,
  IndexTable,
  LendingRateTable,
  OpenInterestChangeTable,
  SimpleEarnExTable,
  TokenSupplyInfoTable,
} from '../components/DataTable'
import { FuturePremTable } from '../components/DataTable/FuturePremTable'
import Heading from '../components/Heading'
import Spinner from '../components/Spinner'
import Tabs from '../components/Tabs'
import PAGE_SCHEMA_CONFIG from '../constants/pageSchemaConfig'
import { useCoinStreamData } from '../hooks/useCoinStreamData'
import { styled } from '../styles'
import { TableMetaConfig } from '../types/business'
import { isNullOrUndefined } from '../utils'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getTableComp = (type: TableMetaConfig['type']): React.FC<any> | null => {
  switch (type) {
    case 'FutureDelivery':
      return FutureDeliveryTable
    case 'FundingRate':
      return FundingRateTable
    case 'FutureSwap':
      return FutureSwapTable
    case 'FuturePrem':
      return FuturePremTable
    case 'Index':
      return IndexTable
    case 'FxRate':
      return FxRateTable
    case 'LendingRate':
      return LendingRateTable
    case 'DefiApy':
      return DefiApyTable
    case 'CoinPrice':
      return CoinPriceTable
    case 'CoinPriceFuture':
      return CoinPriceFutureTable
    case 'CoinPairInfo':
      return CoinPairInfoTable
    case 'TokenSupplyInfo':
      return TokenSupplyInfoTable
    case 'AllStableEx':
      return AllStableExTable
    case 'DerivativeEx':
      return DerivativeExTable
    case 'DerivativeExApy':
      return DerivativeExTableApy
    case 'DerivativeExV2':
      return DerivativeExTableV2
    case 'OpenInterestChange':
      return OpenInterestChangeTable
    case 'SimpleEarnEx':
      return SimpleEarnExTable
    default:
      return null
  }
}

const TableWrapper = styled('div', {})

const ExchangeDetails = ({
  configKey,
}: {
  configKey: 'exchangeName' | 'coinName' | 'overviewName'
}): JSX.Element => {
  const params = useParams()
  const currentConfig = useMemo(
    () => PAGE_SCHEMA_CONFIG[params[configKey] || ''],
    [params, configKey]
  )
  // const currentConfig = EXCHANGE_CONFIG[params[configKey] || '']

  const { data } = useCoinStreamData(currentConfig.dataCategory)

  const dataIsReady = data && data.tabName === params[configKey]
  return (
    <>
      <Heading size="large">{currentConfig.title}</Heading>
      {!dataIsReady && <Spinner />}
      {dataIsReady && currentConfig && (
        <>
          <Tabs
            labels={currentConfig.tabs.labels}
            defaultValue={currentConfig.tabs.defaultValue}
          >
            {currentConfig.tabs.labels.map(label => (
              <Tabs.Content
                key={label}
                value={label}
                css={{
                  ...(currentConfig.tabs.gridProperties?.[label]
                    ? {
                        display: 'grid',
                        gridTemplateColumns:
                          currentConfig.tabs.gridProperties[label]
                            .gridTemplateColumns,
                        gridTemplateAreas:
                          currentConfig.tabs.gridProperties[label]
                            .gridTemplateAreas,
                      }
                    : {}),
                }}
              >
                {currentConfig.tables
                  .filter(table => table.tab === label)
                  .map((table, index) => {
                    const TableComp = getTableComp(table.type)

                    if (!TableComp) {
                      throw new Error(`Unsupport Table Type: ${table.type}`)
                    }

                    const dataBeforeTransform = isNullOrUndefined(table.dataKey)
                      ? data
                      : get(data, table.dataKey)

                    const wrapperKey = `${table.dataKey}_${index}`
                    return (
                      <TableWrapper
                        key={wrapperKey}
                        css={{
                          marginRight: '$small',
                          ...(table.gridArea
                            ? { gridArea: table.gridArea }
                            : {}),
                        }}
                      >
                        {table.tabeleHeader ? (
                          <table.tabeleHeader {...data} />
                        ) : null}
                        <TableComp
                          {...table.tableProps}
                          {...(table.tablePropsExtractor
                            ? table.tablePropsExtractor(data)
                            : {})}
                          dataCategory={currentConfig.dataCategory}
                          data={
                            table.transformData
                              ? table.transformData(dataBeforeTransform)
                              : dataBeforeTransform
                          }
                        />
                      </TableWrapper>
                    )
                  })}
              </Tabs.Content>
            ))}
          </Tabs>
        </>
      )}
    </>
  )
}

export default ExchangeDetails
