import { styled } from '../../../styles'

type NumericValueCellProps = {
  prefix?: string
  postfix?: string
  value: number | null
  decimalPlace?: number
}

const Wrapper = styled('div', {
  textAlign: 'right',
})

export const NumericValueCell: React.FC<NumericValueCellProps> = ({
  prefix = '',
  postfix = '',
  value,
  decimalPlace = 2,
}) => {
  return (
    <Wrapper>
      {value === null
        ? 'N/A'
        : `${prefix}${value.toFixed(decimalPlace)}${postfix}`}
    </Wrapper>
  )
}
