import { Fragment } from 'react'

import { FxRateTable } from '../../components/DataTable'
import Heading from '../../components/Heading'
import Spinner from '../../components/Spinner'
import Tabs from '../../components/Tabs'
import Text from '../../components/Text'
import { useCoinStreamData } from '../../hooks/useCoinStreamData'
import { ETFPageStreamData } from '../../types/etf'
import ETFBidAskTable from './components/ETFBidAskTable'
import ETFLivePricesTable from './components/ETFLivePricesTable'
import ETFSignalsTable from './components/ETFSignalsTable'
import ETFTradingPairTable from './components/ETFTradingPairTable'
import { TMinusDataEditProvider } from './components/tMinusDataEditState'
import TMinusParameterTab from './components/TMinusParameterTab'
import transformData from './transform'

const ETF = (): JSX.Element => {
  const { data: streamData } = useCoinStreamData<ETFPageStreamData>('ETF')

  const [data, error] = transformData(streamData)

  if (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    return (
      <div>
        <Heading>ETF</Heading>
        <Text>Internal Error</Text>
        <pre>{error.message}</pre>
      </div>
    )
  }

  const dataIsReady = data && data.tabName === 'ETF'

  return (
    <div>
      <Heading>ETF</Heading>
      {!dataIsReady && <Spinner />}
      {dataIsReady && (
        <Tabs labels={['Spread', 'T-1 parameter']} defaultValue="Spread">
          <Tabs.Content key="Spread" value="Spread">
            <ETFLivePricesTable data={data.priceInfo} />
            <br />
            <FxRateTable title="" data={data.fxRates} />
            <br />
            <ETFBidAskTable data={data.priceInfo} />
            <br />
            <ETFSignalsTable data={data.priceInfo} />
            <br />
            {data.coins.map(coin => {
              return (
                <Fragment key={coin}>
                  <Heading>{coin} Trading Pairs</Heading>
                  <ETFTradingPairTable
                    title=""
                    data={data.tradingPairs[coin]}
                  />
                </Fragment>
              )
            })}
          </Tabs.Content>
          <Tabs.Content key="T-1 parameter" value="T-1 parameter">
            <TMinusDataEditProvider initData={data.parameters}>
              <TMinusParameterTab data={data} />
            </TMinusDataEditProvider>
          </Tabs.Content>
        </Tabs>
      )}
    </div>
  )
}

export default ETF
