import { Fragment } from 'react'

import { Comp as Base } from '../../../components/DataTable/base'
import { ETFPriceInfo } from '../../../types/etf'
import { thousandSeparate } from '../../../utils'
import { coinEtfMap } from '../constants'

type ETFBidAskTableProps = {
  data: Record<string, ETFPriceInfo>
}

const ETFBidAskTable: React.FC<ETFBidAskTableProps> = ({ data }) => {
  return (
    <>
      <Base.Table>
        <Base.Thead>
          <Base.Tr>
            <Base.Th rowSpan={2} css={{ minWidth: '200px' }}>
              Instrument
            </Base.Th>
            {Object.keys(data).map(coin => {
              return (
                <Base.Th key={coin} colSpan={2} css={{ minWidth: '300px' }}>
                  {coinEtfMap[coin] || coin}
                </Base.Th>
              )
            })}
          </Base.Tr>
          <Base.Tr>
            {Object.keys(data).map(coin => {
              return (
                <Fragment key={coin}>
                  <Base.Th>Bid</Base.Th>
                  <Base.Th>Ask</Base.Th>
                </Fragment>
              )
            })}
          </Base.Tr>
        </Base.Thead>
        <Base.Tbody>
          <Base.Tr>
            <Base.Td>CSOP ETF</Base.Td>
            {Object.keys(data).map(coin => {
              return (
                <Fragment key={coin}>
                  <Base.Td>{thousandSeparate(data[coin].etfQuote.bid)}</Base.Td>
                  <Base.Td>{thousandSeparate(data[coin].etfQuote.ask)}</Base.Td>
                </Fragment>
              )
            })}
          </Base.Tr>
          <Base.Tr>
            <Base.Td>CME 1st</Base.Td>
            {Object.keys(data).map(coin => {
              return (
                <Fragment key={coin}>
                  <Base.Td>
                    {thousandSeparate(data[coin].cmeFirstMonthQuote.bid)}
                  </Base.Td>
                  <Base.Td>
                    {thousandSeparate(data[coin].cmeFirstMonthQuote.ask)}
                  </Base.Td>
                </Fragment>
              )
            })}
          </Base.Tr>
          <Base.Tr>
            <Base.Td>CME 2nd</Base.Td>
            {Object.keys(data).map(coin => {
              return (
                <Fragment key={coin}>
                  <Base.Td>
                    {thousandSeparate(data[coin].cmeSecondMonthQuote.bid)}
                  </Base.Td>
                  <Base.Td>
                    {thousandSeparate(data[coin].cmeSecondMonthQuote.ask)}
                  </Base.Td>
                </Fragment>
              )
            })}
          </Base.Tr>
          <Base.Tr>
            <Base.Td>Binance Spot</Base.Td>
            {Object.keys(data).map(coin => {
              return (
                <Fragment key={coin}>
                  <Base.Td>
                    {thousandSeparate(data[coin].spotQuote.bid)}
                  </Base.Td>
                  <Base.Td>
                    {thousandSeparate(data[coin].spotQuote.ask)}
                  </Base.Td>
                </Fragment>
              )
            })}
          </Base.Tr>
        </Base.Tbody>
      </Base.Table>
    </>
  )
}

export default ETFBidAskTable
