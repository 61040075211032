import { useMemo } from 'react'

import { ContractType, DerivativeExV2 } from '../../types/business'
import DataTable, { DataTableColumns } from './base'
import { ExDataCell } from './components'

type DerivativeExTableV2Props = {
  columnLabels: string[]
  data: DerivativeExV2[]
  title?: string
  contractType: ContractType
}

type TransformedData = {
  instrumentId: DerivativeExV2['alias']
  exchangeData: DerivativeExV2
}

const transformData = (data: DerivativeExV2[]) => {
  const btcEthData: TransformedData[] = []
  const restData: TransformedData[] = []

  data.forEach(el => {
    const instrumentId = el.alias || el.commonId
    const key = instrumentId
    if (key.includes('btc') || key.includes('eth')) {
      btcEthData.push({
        instrumentId,
        exchangeData: el,
      })
    } else {
      restData.push({
        instrumentId,
        exchangeData: el,
      })
    }
  })

  return [...btcEthData.sort(), ...restData.sort()]
}

export const DerivativeExTableV2: React.FC<DerivativeExTableV2Props> = ({
  columnLabels,
  data,
  title,
  contractType,
}) => {
  const columns: DataTableColumns<TransformedData> = useMemo(() => {
    return [
      {
        label: 'Instruments',
        render: row => row.instrumentId,
      },
      ...(columnLabels.map(colLabel => {
        return {
          label: colLabel.replace(/Future$/, ''),
          cellStyles: {
            padding: '0',
          },
          render: row => (
            <ExDataCell
              contractType={contractType}
              data={row.exchangeData.datas[colLabel]}
            />
          ),
        }
      }) as DataTableColumns<TransformedData>),
    ]
  }, [contractType, columnLabels])

  const transformedData = transformData(data)

  return (
    <DataTable
      rowKey=""
      title={title ?? ''}
      columns={columns}
      data={transformedData}
    />
  )
}
