import { useMemo } from 'react'

import {
  AllStableEx,
  AllStableExData,
  ContractType,
} from '../../types/business'
import DataTable, { DataTableColumns } from './base'
import { StableExDataCell } from './components'

type AllStableExTableProps = {
  columnLabels: string[]
  data: AllStableEx[]
  title?: string
  contractType: ContractType
}

type TransformedData = {
  instrumentId: AllStableEx['alias']
  exchangeData: Record<string, AllStableExData | null>
}

const transformData = (data: AllStableEx[], columnLabels: string[]) => {
  // console.log(data)
  const transformedData = data.map(el => {
    const exchangeData = columnLabels.reduce((acc, exchangeLabel, index) => {
      return {
        ...acc,
        [exchangeLabel]: el.datas[index],
      }
    }, {}) as AllStableExData
    return {
      instrumentId: el.alias || el.commonId,
      exchangeData,
    } as unknown as TransformedData
  })

  const btcEthData: TransformedData[] = []
  const restData: TransformedData[] = []

  transformedData.forEach(el => {
    restData.push(el)
  })

  return [...btcEthData.sort(), ...restData.sort()]
}

export const AllStableExTable: React.FC<AllStableExTableProps> = ({
  columnLabels,
  data,
  title,
  contractType,
}) => {
  const columns: DataTableColumns<TransformedData> = useMemo(() => {
    return [
      {
        label: '',
        render: row => row.instrumentId,
      },
      ...(columnLabels.map(colLabel => {
        return {
          label: colLabel.replace(/Future$/, ''),
          cellStyles: {
            padding: '0',
          },
          render: row => (
            <StableExDataCell data={row.exchangeData[colLabel] || null} />
          ),
        }
      }) as DataTableColumns<TransformedData>),
    ]
  }, [contractType, columnLabels])

  const transformedData = transformData(data, columnLabels)

  return (
    <DataTable
      rowKey=""
      title={title ?? ''}
      columns={columns}
      data={transformedData}
    />
  )
}
