import { keyframes, styled } from '../styles'

const spin = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
})

const Spinner = styled('div', {
  borderRadius: '50%',
  $$spinnerSize: '60px',
  $$spinnerWidth: '5px',
  $$spinnerShadow: 'rgba(217,217,217, 0.2)',
  $$spinnerFilled: '#d9d9d9',
  width: '$$spinnerSize',
  height: '$$spinnerSize',
  margin: 'calc($$spinnerSize / 2) auto',
  fontSize: '10px',
  position: 'relative',
  borderTop: '$$spinnerWidth solid $$spinnerShadow',
  borderRight: '$$spinnerWidth solid $$spinnerShadow',
  borderBottom: '$$spinnerWidth solid $$spinnerShadow',
  borderLeft: '$$spinnerWidth solid $$spinnerFilled',
  animation: `${spin} 1.1s infinite linear`,
})

export default Spinner
