import Button from '../../Button'

type ButtonCellProps = {
  children: React.ReactNode
  onClick: () => void
}

export const ButtonCell: React.FC<ButtonCellProps> = ({
  onClick,
  children,
}) => {
  return (
    <Button size="small" convex unsetHeight onClick={() => onClick()}>
      {children}
    </Button>
  )
}
