type FetchConfig = {
  data?: Record<string, unknown>
  method?: string
  headers?: Record<string, string>
}

const baseUrl = import.meta.env.VITE_API_BASE_URL || ''

const getUrl = (apiBaseUrl: string, path: string) => {
  return `${apiBaseUrl}${path}`
}

const customFetch = async <T>(
  path: string,
  { data, method = 'GET', headers = {} }: FetchConfig
) => {
  const res = await fetch(getUrl(baseUrl, path), {
    headers: { 'Content-Type': 'application/json', ...headers },
    mode: 'cors',
    method,
    ...(data ? { body: JSON.stringify(data) } : {}),
  })

  const resData = (await res.json()) as T

  return resData
}

export const customFetchFile = async (
  path: string,
  { data, method = 'GET', headers = {} }: FetchConfig
) => {
  const res = await fetch(getUrl(baseUrl, path), {
    headers: {
      'Content-Type':
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
      ...headers,
    },
    mode: 'cors',
    method,
    ...(data ? { body: JSON.stringify(data) } : {}),
  })

  const resData = (await res.blob()) as Blob

  return resData
}
export default customFetch
