import { CoinPrice, ExchangeDetailsConfig, FxRate } from '../../types/business'
import { COIN } from '../dataCategory'

type ETHStreamData = {
  fxRates: FxRate[]
  physicalPrices: CoinPrice[]
  futurePrices: CoinPrice[]
  tabName: string
}

const tabs = ['All']

export const ETHConfig: ExchangeDetailsConfig<ETHStreamData> = {
  title: 'ETH',
  dataCategory: COIN.ETH,
  tabs: {
    defaultValue: tabs[0],
    labels: tabs,
  },
  tables: [
    {
      type: 'FxRate',
      dataKey: 'fxRates',
      tableProps: null,
      tab: tabs[0],
    },
    {
      type: 'CoinPrice',
      dataKey: 'physicalPrices',
      tableProps: null,
      tab: tabs[0],
    },
    {
      type: 'CoinPriceFuture',
      dataKey: 'futurePrices',
      tableProps: null,
      tab: tabs[0],
    },
  ],
}
