import { LendingRateInfo } from '../../types/business'
import DataTable, { DataTableColumns } from './base'

type LendingRateTableProps = {
  data: LendingRateInfo[]
}

const columns: DataTableColumns<LendingRateInfo> = [
  {
    label: 'Coin',
    render: row => row.symbol,
  },
  {
    label: '3 Days',
    render: row => row.threeDays,
  },
  {
    label: '7 Days',
    render: row => row.sevenDays,
  },
  {
    label: '15 Days',
    render: row => row.fifteenDays,
  },
  {
    label: '30 Days',
    render: row => row.thirtyDays,
  },
  {
    label: 'Current Rate',
    render: row => row.currentRate,
  },
  {
    label: 'Estimate Rate',
    render: row => row.estRate,
  },
]

export const LendingRateTable: React.FC<LendingRateTableProps> = ({
  data = [],
}) => {
  return (
    <DataTable
      rowKey="symbol"
      title="Lending Rate"
      columns={columns}
      data={data}
    />
  )
}
