import { ComponentProps } from '@stitches/react'
import { forwardRef } from 'react'

import { styled } from '../styles'

const StyledButton = styled('button', {
  all: 'unset',
  boxSizing: 'border-box',
  position: 'relative',
  fontSize: '$medium',
  fontWeight: 'bold',
  borderRadius: '$round',
  color: 'white',
  backgroundColor: '$primary',
  lineHeight: 0,
  width: 'min-content',
  cursor: 'pointer',

  '&:hover:enabled': {
    filter: 'brightness(0.9)',
  },
  '&:active:enabled': {
    filter: 'brightness(0.8)',
  },
  '&:focus': {
    focusOutline: '$focusOutlineColor',
  },
  '&:disabled': {
    color: '$disabledText',
    backgroundColor: '$disabledBackground',
    pointerEvents: 'none',
  },

  variants: {
    size: {
      small: {
        padding: '$min',
        fontSize: '$small',
      },
      medium: {
        padding: '$tiny',
      },
    },
  },
  defaultVariants: {
    size: 'medium',
  },
})

type IconButtonProps = {
  icon: JSX.Element
} & ComponentProps<typeof StyledButton>

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ type = 'button', icon, ...rest }, ref) => (
    <StyledButton {...rest} {...{ type, ref }}>
      {icon}
    </StyledButton>
  )
)

IconButton.displayName = 'IconButton'

export default IconButton
