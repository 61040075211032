import { ChangeEventHandler } from 'react'

import { styled } from '../../../styles'

type CheckboxCellProps = {
  defaultChecked?: boolean
  onChange: (val: boolean) => void
}

const Wrapper = styled('label', {
  cursor: 'pointer',
  padding: '0',
  display: 'inline-block',
  width: '100%',
})

export const CheckboxCell: React.FC<CheckboxCellProps> = ({
  defaultChecked = false,
  onChange,
}) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
    onChange(e.target.checked)
  }
  return (
    <Wrapper>
      <input
        type="checkbox"
        defaultChecked={defaultChecked}
        onChange={handleChange}
      />
    </Wrapper>
  )
}
