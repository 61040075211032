import Heading from '../../components/Heading'

const Dashboard = (): JSX.Element => {
  return (
    <div>
      <Heading>Welcome to CoinStream Web. This is a landing page</Heading>
    </div>
  )
}

export default Dashboard
