import { OpenInterestChange } from '../../types/business'
import { thousandSeparate } from '../../utils'
import Text from '../Text'
import DataTable, { DataTableColumns } from './base'

type OpenInterestChangeTableProps = {
  data: OpenInterestChange[]
}

const columns: DataTableColumns<OpenInterestChange> = [
  {
    label: '#',
    render: (row, ctx) => ctx.index + 1,
  },
  {
    key: 'Instruments1',
    label: 'Instruments',
    render: row => row.increaseInstrumentName,
  },
  {
    key: 'Change1',
    label: 'Change',
    render: row => (
      <Text css={{ color: '$success' }}>
        {thousandSeparate(row.increaseChange)}
      </Text>
    ),
  },
  {
    key: 'Instruments2',
    label: 'Instruments',
    render: row => row.decreaseInstrumentName,
  },
  {
    key: 'Change2',
    label: 'Change',
    render: row => (
      <Text css={{ color: '$warn' }}>
        {thousandSeparate(row.decreaseChange)}
      </Text>
    ),
  },
]

export const OpenInterestChangeTable: React.FC<
  OpenInterestChangeTableProps
> = ({ data = [] }) => {
  return <DataTable rowKey="" title="" columns={columns} data={data} />
}
