import { debounce } from 'lodash-es'
import { useEffect, useState } from 'react'

import { styled } from '../../styles'
import CoinStreamDataStream from '../../utils/CoinStreamDataStream'
import Button from '../Button'

const Hint = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  backgroundColor: '$primary',
  borderRadius: '$medium',
  fontSize: '$small',
  padding: '$tiny',
  textAlign: 'center',
  transform: 'translate(0, 100%)',
})

const Arrow = styled('div', {
  position: 'absolute',
  height: 0,
  width: '10px',
  borderStyle: 'solid',
  borderColor: 'transparent',
  borderWidth: '10px',
  borderTopWidth: '0',
  borderBottomColor: '$primary',
  top: '0',
  left: '50%',
  transform: 'translate(-50%, -100%)',
})

const DataStreamStatus: React.FC = () => {
  const [isHealthy, setHealthy] = useState<boolean>(false)

  useEffect(() => {
    const interval = setInterval(() => {
      if (CoinStreamDataStream.isHealthy()) {
        setHealthy(true)
      } else {
        setHealthy(false)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  const handleClick = () => {
    if (!isHealthy) {
      CoinStreamDataStream.reconnectDataStream()
    }
  }

  return (
    <div>
      <Button
        css={{
          fontSize: '$small',
          backgroundColor: !isHealthy ? '$warn' : '',
          pointerEvents: isHealthy ? 'none' : '',
          width: '120px',
        }}
        unsetHeight
        size="small"
        onClick={debounce(handleClick, 1000)}
        title="Click to reconnect"
      >
        <span>DataStream</span>
        <br />
        <span>{isHealthy ? 'ON' : 'OFF'}</span>
      </Button>
      {!isHealthy && (
        <Hint>
          <Arrow />
          Click to reconnect
        </Hint>
      )}
    </div>
  )
}

export default DataStreamStatus
