import {
  ExchangeDetailsConfig,
  FundingRateAggregation,
  FutureSwap,
  FxRate,
} from '../../types/business'
import { EXCHANGE } from '../dataCategory'

type KuCoinStreamData = {
  fundingAggInfo: FundingRateAggregation[]
  fxRates: FxRate[]
  allFutModel: FutureSwap[]
  allCoinMFutModel: FutureSwap[]
  tabName: string
}

const tabs = ['All Derivs-USDT', 'All Derivs-Coin', 'Funding Rate', 'Misc']

export const KuCoinConfig: ExchangeDetailsConfig<KuCoinStreamData> = {
  title: 'KuCoin',
  dataCategory: EXCHANGE.KuCoin,
  tabs: {
    defaultValue: tabs[0],
    labels: tabs,
  },
  tables: [
    {
      type: 'FutureSwap',
      dataKey: 'allFutModel',
      tableProps: {
        title: 'KuCoin vs All Derivs-USDT',
      },
      tab: tabs[0],
    },
    {
      type: 'FutureSwap',
      dataKey: 'allCoinMFutModel',
      tableProps: {
        title: 'KuCoin vs All Derivs-Coin',
      },
      tab: tabs[1],
    },
    {
      type: 'FundingRate',
      dataKey: 'fundingAggInfo',
      tableProps: null,
      tab: tabs[2],
    },
    {
      type: 'FxRate',
      dataKey: 'fxRates',
      tableProps: null,
      tab: tabs[3],
    },
  ],
}
