import {
  ExchangeDetailsConfig,
  FundingRateAggregation,
  FutureDeliveryInfo,
  FutureSwap,
  FxRate,
} from '../../types/business'
import { EXCHANGE } from '../dataCategory'

type AscendEXStreamData = {
  fundingAggInfo: FundingRateAggregation[]
  futureInfo: FutureDeliveryInfo[]
  fxRates: FxRate[]
  allFutModel: FutureSwap[]
  tabName: string
}

const tabs = ['All Derivs', 'Funding Rate', 'Misc']

export const AscendEXConfig: ExchangeDetailsConfig<AscendEXStreamData> = {
  title: 'AscendEX',
  dataCategory: EXCHANGE.AscendEX,
  tabs: {
    defaultValue: tabs[0],
    labels: tabs,
  },
  tables: [
    {
      type: 'FutureSwap',
      dataKey: 'allFutModel',
      tableProps: {
        title: 'AscendEx vs All Derivs (Taker / Taker Premium)',
      },
      tab: tabs[0],
    },
    {
      type: 'FundingRate',
      dataKey: 'fundingAggInfo',
      tableProps: null,
      tab: tabs[1],
    },
    {
      type: 'FxRate',
      dataKey: 'fxRates',
      tableProps: null,
      tab: tabs[2],
    },
    {
      type: 'FutureDelivery',
      dataKey: 'futureInfo',
      tableProps: null,
      tab: tabs[2],
    },
  ],
}
