import { set } from 'lodash-es'
import { useMemo, useRef } from 'react'

import customFetch from '../../api/customFetch'
import { Index } from '../../types/business'
import DataTable, { DataTableColumns } from './base'
import { ButtonCell, NumberEditCell } from './components'

type IndexTableProps = {
  dataCategory: string
  data?: Index[]
}

const changeBidAsk = (
  dataCategory: string,
  bid = 0,
  ask = 0,
  exchange: string
) => {
  customFetch('/TargetInfo/ChangePrice', {
    data: {
      tab: dataCategory,
      bid,
      ask,
      exchange,
    },
    method: 'POST',
  })
}

const changeTarget = (dataCategory: string, exchange: string) => {
  customFetch('/TargetInfo/ChangeTarget', {
    data: {
      tab: dataCategory,
      exchange,
    },
    method: 'POST',
  })
}

export const IndexTable: React.FC<IndexTableProps> = ({
  dataCategory,
  data = [],
}) => {
  const bidAskMap = useRef({} as Record<string, Record<'bid' | 'ask', number>>)
  const columns: DataTableColumns<Index> = useMemo(
    () => [
      { label: 'Exchange', render: row => row.exchange },
      { label: 'Bid', render: row => row.bid },
      { label: 'Ask', render: row => row.ask },
      {
        label: 'Bid',
        key: 'bidEditable',
        width: '170px',
        render: (row, ctx) => (
          <NumberEditCell
            disabled={ctx.index === 0}
            defaultValue={row.bidEditable}
            onChange={val => {
              const { exchange } = row
              set(bidAskMap.current, [exchange, 'bid'], val)
              changeBidAsk(
                dataCategory,
                val,
                bidAskMap.current[exchange]?.ask,
                exchange
              )
            }}
          />
        ),
      },
      {
        label: 'Ask',
        key: 'askEditable',
        width: '170px',
        render: (row, ctx) => (
          <NumberEditCell
            disabled={ctx.index === 0}
            defaultValue={row.askEditable}
            onChange={val => {
              const { exchange } = row
              set(bidAskMap.current, [exchange, 'ask'], val)
              changeBidAsk(
                dataCategory,
                bidAskMap.current[exchange]?.bid,
                val,
                exchange
              )
            }}
          />
        ),
      },
      { label: 'Index bid', render: row => row.indexBid },
      { label: 'Index ask', render: row => row.indexAsk },
      {
        label: 'Use',
        render: row => (
          <ButtonCell onClick={() => changeTarget(dataCategory, row.exchange)}>
            {row.use ? 'yes' : 'no'}
          </ButtonCell>
        ),
      },
    ],
    [dataCategory]
  )

  return (
    <DataTable rowKey="exchange" title="Index" columns={columns} data={data} />
  )
}
