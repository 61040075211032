import { FutureSwap, FxRate } from '../../types/business'
import {
  ETFCoinInsturmentFutureSwapType,
  ETFPageStreamData,
  ETFPriceInfo,
  ETFTMinusData,
  Quote,
} from '../../types/etf'
import { toPercentStr } from '../../utils'
import { coinEtfLotSizeMap, coinEtfTickerMap } from './constants'

export type TransformedETFPageStreamData = {
  fxRates: FxRate[]
  allFutModel: FutureSwap[]
  parameters: Record<string, ETFTMinusData>
  coins: string[]
  instrumentMap: Record<
    string,
    Record<ETFCoinInsturmentFutureSwapType, FutureSwap>
  >
  priceInfo: Record<string, ETFPriceInfo>
  tradingPairs: Record<string, FutureSwap[]>
  tabName: string
}

const coinNameToUpperCase = (data: ETFPageStreamData): ETFPageStreamData => {
  const newParameters = Object.keys(data.parameters).reduce(
    (acc, lowerCaseCoin) => {
      return {
        ...acc,
        [lowerCaseCoin.toUpperCase()]: data.parameters[lowerCaseCoin],
      }
    },
    {}
  ) as ETFPageStreamData['parameters']

  return {
    ...data,
    parameters: newParameters,
  }
}

const mapEtfModel = (
  data: ETFPageStreamData
): ETFPageStreamData & {
  coins: string[]
  instrumentMap: Record<string, Record<string, FutureSwap>>
} => {
  const coins = Object.keys(data.parameters)
  const instrumentMap = coins.reduce((acc, coin) => {
    const csopEtf = data.allFutModel.find(
      el => el.instrumentId === coinEtfTickerMap[coin]
    )
    if (!csopEtf) {
      throw new Error(`Missing CSOP ETF for ${coin}`)
    }

    const cmeFutureFirstMonth = data.allFutModel.find(
      el =>
        el.instrumentId === data.parameters[coin]?.cmeFutureFirstMonthTickerName
    )
    if (!cmeFutureFirstMonth) {
      throw new Error(`Missing CME First Month Future for ${coin}`)
    }

    const cmeFutureSecondMonth = data.allFutModel.find(
      el =>
        el.instrumentId ===
        data.parameters[coin]?.cmeFutureSecondMonthTickerName
    )
    if (!cmeFutureSecondMonth) {
      throw new Error(`Missing CME Second Month Future for ${coin}`)
    }

    const spot = data.allFutModel.find(el => el.instrumentId === `${coin}USDT`)
    if (!spot) {
      throw new Error(`Missing spot for ${coin}`)
    }
    const coinInstrumentMap = {
      csopEtf,
      cmeFutureFirstMonth,
      cmeFutureSecondMonth,
      spot,
    } as Record<ETFCoinInsturmentFutureSwapType, FutureSwap>

    return {
      ...acc,
      [coin]: coinInstrumentMap,
    }
  }, {} as Record<string, Record<string, FutureSwap>>)

  return {
    ...data,
    coins,
    instrumentMap,
  }
}

const calculateTMinusData = (
  data: ETFPageStreamData & {
    coins: string[]
    instrumentMap: Record<string, Record<string, FutureSwap>>
  }
): Omit<TransformedETFPageStreamData, 'priceInfo' | 'tradingPairs'> => {
  const hkdRate = data.fxRates.find(el => el.currency === 'HKD')
  if (!hkdRate) {
    throw new Error('Missing HKD fx rate')
  }

  const newParameters = data.coins.reduce((acc, coin) => {
    const param = data.parameters[coin]
    if (!param) {
      throw new Error(`Missing ${coin} parameter`)
    }
    const instrumentMap = data.instrumentMap[coin]
    if (!param) {
      throw new Error(`Missing ${coin} instrumentMap`)
    }

    const contractSize = coinEtfLotSizeMap[coin] || 1
    const etfMarkVal = param.navPerUnitInUsd * param.sharesOutstanding
    const cmeFutureFirstMonthLive =
      (instrumentMap.cmeFutureFirstMonth.bid +
        instrumentMap.cmeFutureFirstMonth.ask) /
      2
    const cmeFutureSecondMonthLive =
      (instrumentMap.cmeFutureSecondMonth.bid +
        instrumentMap.cmeFutureSecondMonth.ask) /
      2

    const firstMonthMarkValTMinus =
      param.futuresFirstMonth * contractSize * param.cmeFutureFirstMonthTMinus
    const secondMonthMarkValTMinus =
      param.futuresSecondMonth * contractSize * param.cmeFutureSecondMonthTMinus

    const prCalc =
      (firstMonthMarkValTMinus + secondMonthMarkValTMinus) / etfMarkVal
    const firstMonthPr = firstMonthMarkValTMinus / etfMarkVal
    const secondMonthPr = secondMonthMarkValTMinus / etfMarkVal

    const cash = etfMarkVal * (1 - prCalc)

    // calculate underlying asset value delta, multiple the PR of the asset to get the actual delta towards the fair value
    const navFairValueLive =
      param.navPerUnitInUsd *
      (firstMonthPr *
        (cmeFutureFirstMonthLive / param.cmeFutureFirstMonthTMinus - 1) +
        secondMonthPr *
          (cmeFutureSecondMonthLive / param.cmeFutureSecondMonthTMinus - 1) +
        1) *
      hkdRate.rate

    const etfTMinusData: ETFTMinusData = {
      ...param,
      pr: param.pr * 100,
      prCalc, // range from 0 to 1
      prRecon: Math.abs(prCalc - param.pr) < 0.0001,
      cash,
      navFairValueLive,
      cmeFutureFirstMonthLive,
      cmeFutureSecondMonthLive,
      firstMonthPr,
      secondMonthPr,
    }

    return {
      ...acc,
      [coin]: etfTMinusData,
    }
  }, {}) as Record<string, ETFTMinusData>

  return {
    ...data,
    parameters: newParameters,
  }
}

const calculateEtfPriceInfo = (
  data: Omit<TransformedETFPageStreamData, 'priceInfo' | 'tradingPairs'>
): Omit<TransformedETFPageStreamData, 'tradingPairs'> => {
  const priceInfo = data.coins.reduce((acc, coin) => {
    const param = data.parameters[coin]
    if (!param) {
      throw new Error(`Missing param for ${coin}`)
    }

    const instrumentMap = data.instrumentMap[coin] as Record<
      ETFCoinInsturmentFutureSwapType,
      FutureSwap
    >

    if (!instrumentMap) {
      throw new Error(`Missing instrumentMap for ${coin}`)
    }

    const etfPriceLive =
      (instrumentMap.csopEtf.bid + instrumentMap.csopEtf.ask) / 2
    const cmeReplicationPrice = param.navFairValueLive
    const etfQuote: Quote = {
      bid: instrumentMap.csopEtf.bid,
      ask: instrumentMap.csopEtf.ask,
    }
    const cmeFirstMonthQuote: Quote = {
      bid: instrumentMap.cmeFutureFirstMonth.bid,
      ask: instrumentMap.cmeFutureFirstMonth.ask,
    }
    const cmeSecondMonthQuote: Quote = {
      bid: instrumentMap.cmeFutureSecondMonth.bid,
      ask: instrumentMap.cmeFutureSecondMonth.ask,
    }
    const spotQuote: Quote = {
      bid: instrumentMap.spot.bid,
      ask: instrumentMap.spot.ask,
    }
    const etfCmeFairMidSpreadQuote: Quote = {
      bid: instrumentMap.csopEtf.bid / cmeReplicationPrice - 1,
      ask: instrumentMap.csopEtf.ask / cmeReplicationPrice - 1,
    }
    const cmeFirstMonthSpotSpreadQuote: Quote = {
      bid: instrumentMap.cmeFutureFirstMonth.bid / instrumentMap.spot.ask - 1,
      ask: instrumentMap.cmeFutureFirstMonth.ask / instrumentMap.spot.bid - 1,
    }
    const cmeSecondMonthSpotSpreadQuote: Quote = {
      bid: instrumentMap.cmeFutureSecondMonth.bid / instrumentMap.spot.ask - 1,
      ask: instrumentMap.cmeFutureSecondMonth.ask / instrumentMap.spot.bid - 1,
    }
    const etfSpotSpreadQuote: Quote = {
      bid:
        (etfCmeFairMidSpreadQuote.bid + 1) *
          (cmeFirstMonthSpotSpreadQuote.ask + 1) -
        1,
      ask:
        (etfCmeFairMidSpreadQuote.ask + 1) /
          (cmeFirstMonthSpotSpreadQuote.bid + 1) -
        1,
    }

    const cmeSecondMonthFirstMonthSpreadQuote: Quote = {
      bid: cmeSecondMonthQuote.bid / cmeFirstMonthQuote.ask - 1,
      ask: cmeSecondMonthQuote.ask / cmeFirstMonthQuote.bid - 1,
    }

    const coinPriceInfo = {
      etfPriceLive,
      cmeReplicationPrice,
      etfQuote,
      cmeFirstMonthQuote,
      cmeSecondMonthQuote,
      spotQuote,
      bbgSpotIndex: 0,
      etfCmeFairMidSpreadQuote,
      cmeFirstMonthSpotSpreadQuote,
      cmeSecondMonthSpotSpreadQuote,
      etfSpotSpreadQuote,
      cmeSecondMonthFirstMonthSpreadQuote,
    }

    return {
      ...acc,
      [coin]: coinPriceInfo,
    }
  }, {}) as Record<string, ETFPriceInfo>
  return {
    ...data,
    priceInfo,
  }
}

const addSpreadBidAskToFutModel = (
  data: Omit<TransformedETFPageStreamData, 'tradingPairs'>
): TransformedETFPageStreamData => {
  const ret = {} as TransformedETFPageStreamData['tradingPairs']
  data.coins.forEach(coin => {
    const instrumentMap = data.instrumentMap[coin]
    const priceInfo = data.priceInfo[coin]
    const newData: Record<string, FutureSwap> = {
      cmeFairMid: {
        instrumentId: 'CME Fair (HKD)',
        exchange: 'CME',
        ask: Number(priceInfo.cmeReplicationPrice.toFixed(4)),
        bid: Number(priceInfo.cmeReplicationPrice.toFixed(4)),
        askSize: 0,
        bidSize: 0,
        rawAsk: 0,
        rawBid: 0,
      } as FutureSwap,
      csopEtf: {
        ...instrumentMap.csopEtf,
        futPremiumBid: toPercentStr(priceInfo.etfCmeFairMidSpreadQuote.bid, 2),
        futPremiumAsk: toPercentStr(priceInfo.etfCmeFairMidSpreadQuote.ask, 2),
      },
      spot: instrumentMap.spot,
      cmeFirstMonth: {
        ...instrumentMap.cmeFutureFirstMonth,
        futPremiumBid: toPercentStr(
          priceInfo.cmeFirstMonthSpotSpreadQuote.bid,
          2
        ),
        futPremiumAsk: toPercentStr(
          priceInfo.cmeFirstMonthSpotSpreadQuote.ask,
          2
        ),
      },
      cmeSecondMonth: {
        ...instrumentMap.cmeFutureSecondMonth,
        futPremiumBid: toPercentStr(
          priceInfo.cmeSecondMonthSpotSpreadQuote.bid,
          2
        ),
        futPremiumAsk: toPercentStr(
          priceInfo.cmeSecondMonthSpotSpreadQuote.ask,
          2
        ),
      },
      etfSpot: {
        ...instrumentMap.csopEtf,
        futPremiumBid: toPercentStr(priceInfo.etfSpotSpreadQuote.bid, 2),
        futPremiumAsk: toPercentStr(priceInfo.etfSpotSpreadQuote.ask, 2),
      },
      cmeFirstMonthRaw: {
        ...instrumentMap.cmeFutureFirstMonth,
      },
      cmeSecondVsFirst: {
        ...instrumentMap.cmeFutureSecondMonth,
        futPremiumBid: toPercentStr(
          priceInfo.cmeSecondMonthFirstMonthSpreadQuote.bid,
          2
        ),
        futPremiumAsk: toPercentStr(
          priceInfo.cmeSecondMonthFirstMonthSpreadQuote.ask,
          2
        ),
      },
    }

    ret[coin] = [
      newData.cmeFairMid,
      newData.csopEtf,
      null,
      newData.spot,
      newData.cmeFirstMonth,
      newData.cmeSecondMonth,
      null,
      newData.spot,
      newData.etfSpot,
      null,
      newData.cmeFirstMonthRaw,
      newData.cmeSecondVsFirst,
    ] as FutureSwap[]
  })
  return { ...data, tradingPairs: ret }
}

const transform = (
  data?: ETFPageStreamData
): [TransformedETFPageStreamData?, Error?] => {
  if (data && data.tabName === 'ETF') {
    try {
      const result = addSpreadBidAskToFutModel(
        calculateEtfPriceInfo(
          calculateTMinusData(mapEtfModel(coinNameToUpperCase(data)))
        )
      )

      return [result, undefined]
    } catch (e) {
      return [undefined, e as Error]
    }
  }

  return [undefined, undefined]
}
export default transform
