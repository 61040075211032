import { CoinPairInfo } from '../../types/business'
import dayjs from '../../utils/dayjs'
import DataTable, { DataTableColumns } from './base'
import { NumberEditCell } from './components'
import { setFilter } from './utils'

type CoinPairInfoTableProps = {
  data: CoinPairInfo[]
  title: string
}

const columns: DataTableColumns<CoinPairInfo> = [
  {
    label: 'Exchange',
    render: row => row.exchange,
  },
  {
    label: 'Currency',
    render: row => row.currency,
  },
  {
    label: 'Bid Size',
    render: row => row.bidSize,
  },
  {
    label: 'Bid',
    render: row => row.bid,
  },
  {
    label: 'Ask',
    render: row => row.ask,
  },
  {
    label: 'Ask Size',
    render: row => row.askSize,
  },
  {
    key: 'span0',
    label: '',
    isEmpty: true,
    render: () => '',
    width: '30px',
  },
  {
    label: 'CCY',
    render: row => row.ccy,
  },
  {
    label: 'Raw Bid',
    render: row => row.raw_bid,
  },
  {
    label: 'Raw Ask',
    render: row => row.raw_ask,
  },
  {
    label: 'Timestamp',
    render: row => dayjs(row.timestamp).format('HH:mm:ss'),
  },
  {
    key: 'span1',
    label: '',
    isEmpty: true,
    render: () => '',
    width: '30px',
  },
  {
    label: 'Taker %',
    width: '170px',
    render: row => (
      <NumberEditCell
        onChange={val => setFilter('taker_commission', row, val)}
      />
    ),
  },
  {
    label: 'Min Size %',
    width: '170px',
    render: row => (
      <NumberEditCell onChange={val => setFilter('min_size', row, val)} />
    ),
  },
]

export const CoinPairInfoTable: React.FC<CoinPairInfoTableProps> = ({
  data,
  title,
}) => {
  return <DataTable rowKey="id" title={title} columns={columns} data={data} />
}
